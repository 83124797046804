import { CoreModel, ISelectOption, modelProtection } from '@wings-shared/core';
import { SettingBaseModel } from '../SettingBase.model';
import { AuthStore } from '@uplink-shared/security';
import { VendorManagmentModel } from '../VendorManagment.model';
import { CityModel, CountryModel, StateModel } from '@uplink/shared';

@modelProtection
export class VendorOnBoardSlideSevenModel extends CoreModel implements ISelectOption {
  id: number = 0;
  vendor: VendorManagmentModel = new VendorManagmentModel();
  vendorId: number = 0;
  tempLocationId: string = '';
  addressType: SettingBaseModel = new SettingBaseModel();
  userId: string = '';
  addressLine1: string = '';
  addressLine2: string = '';
  countryReference: CountryModel;
  stateReference: StateModel;
  cityReference: CityModel;
  advanceOverTimeRequested: string = '';
  overTimeRequested: string = '';
  airfieldLocation: string = '';
  arinc: string = '';
  zipCode: string = '';

  constructor(data?: Partial<VendorOnBoardSlideSevenModel>) {
    super(data);
    Object.assign(this, data);
  }

  public serialize(): VendorOnBoardSlideSevenModel {
    return {
      id: this.id || 0,
      userId: this.userId,
      vendorId: AuthStore.vendorProfile.id,
      addressLine1: this.addressLine1,
      addressLine2: this.addressLine2 || null,
      cityId: this.hqAddressCity.id,
      cityName: this.hqAddressCity.commonName,
      cityCode: this.hqAddressCity.cappsCode,
      stateId: this.hqAddressState?.id,
      stateName: this.hqAddressState?.commonName,
      stateCode: this.hqAddressState?.isoCode,
      countryId: this.hqAddressCountry.id,
      countryCode: this.hqAddressCountry.isO2Code,
      countryName: this.hqAddressCountry.commonName,
      zipCode: this.zipCode ? this.zipCode : null,
      tempLocationId: this.tempLocationId ? this.tempLocationId : null,
      advanceOverTimeRequested: this.advanceOverTimeRequested,
      overTimeRequested: this.overTimeRequested,
      airfieldLocation: this.airfieldLocation,
      arinc: this.arinc,
    };
  }

  static deserialize(apiData: VendorOnBoardSlideSevenModel): VendorOnBoardSlideSevenModel {
    if (!apiData) {
      return new VendorOnBoardSlideSevenModel();
    }
    const data: Partial<VendorOnBoardSlideSevenModel> = {
      ...apiData,
      vendor: VendorManagmentModel.deserialize(apiData.vendor),
      addressType: SettingBaseModel.deserialize(apiData?.addressType),
      countryReference: apiData?.countryReference?.countryId
        ? CountryModel.deserialize(apiData?.countryReference)
        : null,
      stateReference: StateModel.deserialize(apiData?.stateReference),
      cityReference: CityModel.deserialize(apiData?.cityReference),
    };
    return new VendorOnBoardSlideSevenModel(data);
  }

  static deserializeList(apiDataList: VendorOnBoardSlideSevenModel[]): VendorOnBoardSlideSevenModel[] {
    return apiDataList ? apiDataList.map(apiData => VendorOnBoardSlideSevenModel.deserialize(apiData)) : [];
  }

  public get label(): string {
    return this.name;
  }

  public get value(): string | number {
    return this.id;
  }
}
