import { auditFields } from '@uplink/shared';

export const fields = {
  ...auditFields,
  id: {
    label: 'Cabotage Id',
  },
  vendorLocation: {
    label: 'Vendor Location',
  },
  cabotageExemptionLevel: {
    label: 'Cabotage Exemption Level',
  },
  isImportationFeesforDomesticFlight: {
    label: 'Are there importation fees for domestic flights?*',
  },
  isCustomsStopsExempt: {
    label: 'Is there an exemption for customs stops?*',
  },
  isPaxMustDepartwithSameOperator: {
    label: 'Must the passenger depart with the same operator?*',
  },
  isNoNewPaxAllowedtoDepart: {
    label: 'Are new passengers allowed to depart with a given operator?*',
  },
  isCabotageAppliestoLivestock: {
    label: 'Does cabotage apply to livestock?*',
  },
  isCabotageAppliestoCargo: {
    label: 'Does cabotage apply to cargo?*',
  },
  isCabotageAppliestoNonResidents: {
    label: 'Does cabotage apply to non-residents?*',
  },
  cabotageExemptionLevel:{
    label: 'To what level does cabotage apply for you?*',
  },
  appliedCabotageEntities:{
    label: 'Cabotage Entities*',
  },
  appliedRegionCabotageExemptions:{
    label: 'Regions*',
    rules: 'required'
  },
  appliedCountryCabotageExemptions:{
    label: 'Countries*',
    rules: 'required'
  },
  cabotageEnforcedForFARTypes:{
    label: 'FAR Types*',
    rules: 'required'
  },
};
