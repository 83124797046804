import React, { FC, useEffect, useState } from 'react';
import { useGridState } from '@uplink-shared/custom-ag-grid';
import { useUnsubscribe } from '@wings-shared/hooks';
import { CityModel, CountryModel, StateModel, useBaseUpsertComponent } from '@uplink/shared';
import {
  IClasses,
  Utilities,
  IOptionValue,
  IAPIGridRequest,
  UIStore,
  IAPISearchFiltersDictionary,
  SEARCH_ENTITY_TYPE,
} from '@wings-shared/core';
import { ConfirmNavigate, SidebarStore } from '@uplink-shared/layout';
import { Box, Tooltip, Typography, withStyles } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { EDITOR_TYPES, IGroupInputControls } from '@uplink-shared/form-controls';
import {
  BaseStore,
  SlideOneAndTwoStore,
  SlideSevenStore,
  VendorLocationHoursStore,
  VendorLocationStore,
  VendorManagementStore,
} from '../../../../Stores';
import {
  LocationHoursModel,
  newVendorOnboarding,
  OperatingHoursAddData,
  VendorOnBoardSlideSevenModel,
  ViewInputControls,
} from '../../../Shared';
import { finalize, takeUntil, catchError, mergeMap } from 'rxjs/operators';
import { AuthStore } from '@uplink-shared/security';
import { fields } from './Fields';
import { styles } from './Slide7.styles';
import InfoIcon from '@material-ui/icons/Info';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { forkJoin, of } from 'rxjs';

interface Props {
  classes?: IClasses;
  slideSevenStore: SlideSevenStore;
  slideOneAndTwoStore: SlideOneAndTwoStore;
  vendorLocationStore: VendorLocationStore;
  vendorManagementStore: VendorManagementStore;
  vendorLocationHoursStore: VendorLocationHoursStore;
  searchFilters: IAPISearchFiltersDictionary;
  registerSaveData: (saveData: () => void) => void;
  activeStep: number;
  setActiveStep: React.Dispatch<number>;
  onNextButtonDisable: (boolean) => void;
}

const Slide7: FC<Props> = ({
  classes,
  searchFilters,
  slideSevenStore,
  vendorLocationStore,
  vendorManagementStore,
  vendorLocationHoursStore,
  registerSaveData,
  activeStep,
  setActiveStep,
  onNextButtonDisable,
  slideOneAndTwoStore,
}) => {
  const gridState = useGridState();
  const unsubscribe = useUnsubscribe();
  const useUpsert = useBaseUpsertComponent<VendorOnBoardSlideSevenModel>({}, fields, searchFilters);
  const formRef = useUpsert.form;
  const [ countryList, setCountryList ] = useState([]);
  const [ statesList, setStatesList ] = useState([]);

  useEffect(() => {
    registerSaveData(saveData);
    return () => registerSaveData(null);
  }, []);

  const saveData = () => {
    upsertSlideSeven();
  };

  const validateForm = () => {
    return !formRef.isValid || formRef.hasError;
  };

  useEffect(() => {
    onNextButtonDisable(validateForm());
  }, [ formRef.changed, formRef.isValid, formRef.hasError ]);

  useEffect(() => {
    SidebarStore.setNavLinks(newVendorOnboarding(), 'vendor', 'Vendor Locations', '/vendor/locations');
    vendorManagementStore.getVmsCountryCode().subscribe(response => {
      setCountryList(response.results);
    });
    loadInitialData();
    loadOperatingHoursData();
  }, [ activeStep ]);

  const loadOperatingHoursData = () => {
    UIStore.setPageLoader(true);
    slideSevenStore
      .getOnBoardingHours(AuthStore.vendorProfile.id, slideOneAndTwoStore.tempLocationId)
      .subscribe(() => UIStore.setPageLoader(false));
  };
  
  const isCountrySelected = (): boolean => {
    const { value } = useUpsert.getField('hqAddressCountry');
    return Boolean((value as CountryModel)?.id);
  };

  const groupInputControls = (): IGroupInputControls[] => {
    return [
      {
        title: 'General Information:',
        inputControls: [
          {
            fieldKey: 'id',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
          {
            fieldKey: 'addressLine1',
            type: EDITOR_TYPES.TEXT_FIELD,
            isFullFlex: true,
          },
          {
            fieldKey: 'addressLine1',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true,
          },
          {
            fieldKey: 'addressType',
            type: EDITOR_TYPES.DROPDOWN,
            isHidden: true,
          },
          {
            fieldKey: 'hqAddressCountry',
            type: EDITOR_TYPES.DROPDOWN,
            options: countryList,
            searchEntityType: SEARCH_ENTITY_TYPE.COUNTRY,
            isHalfFlex: true,
          },
          {
            fieldKey: 'hqAddressState',
            type: EDITOR_TYPES.DROPDOWN,
            options: statesList,
            isDisabled: !isCountrySelected(),
            searchEntityType: SEARCH_ENTITY_TYPE.STATE,
            getOptionLabel: state => (state as StateModel)?.label,
            isHalfFlex: true,
          },
          {
            fieldKey: 'hqAddressCity',
            type: EDITOR_TYPES.DROPDOWN,
            options: vendorManagementStore.cities,
            isDisabled: !isCountrySelected(),
            isHalfFlex: true,
          },
          {
            fieldKey: 'zipCode',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
          },
          {
            fieldKey: 'airfieldLocation',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
          },
          {
            fieldKey: 'arinc',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
          },
          {
            fieldKey: 'overTimeRequested',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
          },
          {
            fieldKey: 'advanceOverTimeRequested',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHalfFlex: true,
          },
        ],
      },
    ];
  };

  const errorHandler = (errors: object, id): void => {
    Object.values(errors)?.forEach(errorMessage => useUpsert.showAlert(errorMessage[0], id));
  };

  const upsertSlideSeven = () => {
    UIStore.setPageLoader(true);
    const request = new VendorOnBoardSlideSevenModel({
      ...useUpsert.form.values(),
      tempLocationId: slideOneAndTwoStore.tempLocationId,
    });
    slideSevenStore
      .upsertSlideSeven(request.serialize())
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: VendorOnBoardSlideSevenModel) => {
          useUpsert.form.reset();
          setFormValues(response);
          slideOneAndTwoStore.activeStep = 7;
        },
        error: error => {
          if (error.response.data.errors) {
            errorHandler(error.response.data.errors, request.id.toString());
            return;
          }
          useUpsert.showAlert(error.message, request.id.toString());
        },
      });
  };

  const filterStateByCountry = (value?: any) => {
    const filter = value
      ? JSON.stringify([
        {
          propertyName: 'Country.CountryId',
          propertyValue: value.id,
        },
      ])
      : '';

    const request: IAPIGridRequest = {
      filterCollection: filter,
    };
    vendorManagementStore.getVmsStates(request, undefined).subscribe(response => {
      setStatesList(response.results);
    });
  };

  const loadCities = (searchValue: string): void => {
    const countryId: number = useUpsert.getField('hqAddressCountry').value?.id;
    if (!countryId || !searchValue) {
      vendorManagementStore.cities = [];
      return;
    }
    const stateId: number = useUpsert.getField('hqAddressState').value?.id;
    const filters = stateId
      ? Utilities.getFilter('State.StateId', stateId)
      : Utilities.getFilter('Country.CountryId', countryId);

    const searchCityFilter = searchValue
      ? [
        {
          propertyName: 'CommonName',
          propertyValue: searchValue,
        },
        {
          propertyName: 'OfficialName',
          operator: 'or',
          propertyValue: searchValue,
        },
      ]
      : [];

    const filterCollection = [ filters ];
    const request: IAPIGridRequest = {
      filterCollection: JSON.stringify(filterCollection),
      searchCollection: JSON.stringify(searchCityFilter),
    };
    vendorManagementStore.getVmsCities(request).subscribe();
  };

  const onValueChange = (value: IOptionValue, fieldKey: string): void => {
    useUpsert.getField(fieldKey).set(value);
    switch (fieldKey) {
      case 'hqAddressCountry':
        vendorManagementStore.cities = [];
        vendorManagementStore.states = [];
        useUpsert.getField('hqAddressState').clear();
        useUpsert.getField('hqAddressCity').clear();
        filterStateByCountry(value);
        setCountryList(vendorManagementStore.countries);
        loadCities('');
        break;
      case 'hqAddressState':
        vendorManagementStore.cities = [];
        useUpsert.getField('hqAddressCity').clear();
        break;
      case 'hqAddressCity':
        vendorManagementStore.cities = [];
        break;
      default:
        break;
    }
    gridState.hasError = Utilities.hasInvalidRowData(gridState.gridApi);
  };

  const onSearch = (searchValue: string, fieldKey: string): void => {
    switch (fieldKey) {
      case 'hqAddressCountry':
        const filteredList = vendorManagementStore.countries.filter(country => {
          return (
            country.commonName?.toLowerCase().includes(searchValue.toLowerCase()) ||
            country.isO2Code?.toLowerCase().includes(searchValue.toLowerCase())
          );
        });
        setCountryList(filteredList);
        break;
      case 'hqAddressCity':
        loadCities(searchValue);
        break;
      case 'hqAddressState':
        const filteredStates = vendorManagementStore.states.filter(data => {
          return (
            data.commonName?.toLowerCase().includes(searchValue.toLowerCase()) ||
            data.cappsName?.toLowerCase().includes(searchValue.toLowerCase())
          );
        });
        setStatesList(filteredStates);
        break;
      default:
        break;
    }
    return;
  };

  const onFocus = (fieldKey: string): void => {
    switch (fieldKey) {
      case 'hqAddressState':
        const { value } = useUpsert.getField('hqAddressCountry');
        filterStateByCountry(value);
        break;
      case 'hqAddressCountry':
        setCountryList(vendorManagementStore.countries);
        break;
      case 'hqAddressCity':
        if (!useUpsert.getField('hqAddressCity').value) {
          vendorManagementStore.cities = [];
          useUpsert.getField('hqAddressCity').clear();
        }
        break;
      default:
        break;
    }
  };

  const loadInitialData = () => {
    UIStore.setPageLoader(true);
    slideSevenStore
      .getByVendorId(AuthStore.vendorProfile?.id)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe((response: VendorOnBoardSlideSevenModel[]) => {
        setFormValues(VendorOnBoardSlideSevenModel.deserialize(response[0]));
      });
  };

  const setFormValues = vendorAddress => {
    useUpsert.setFormValues(vendorAddress);
    const countryModel = vendorAddress?.countryReference?.countryId
      ? new CountryModel({
        id: vendorAddress?.countryReference?.countryId,
        commonName: vendorAddress?.countryReference?.name,
        isO2Code: vendorAddress?.countryReference?.code,
      })
      : null;
    const stateModel = vendorAddress?.stateReference?.stateId
      ? new StateModel({
        id: vendorAddress?.stateReference?.stateId,
        commonName: vendorAddress?.stateReference?.name,
        code: vendorAddress?.stateReference?.code,
      })
      : null;
    const cityModel = vendorAddress?.cityReference?.id
      ? new CityModel({
        id: vendorAddress?.cityReference?.id,
        cappsCode: vendorAddress?.cityReference?.cappsCode,
        commonName: vendorAddress?.cityReference?.commonName,
      })
      : null;
    useUpsert.getField('hqAddressCountry').set(countryModel);
    useUpsert.getField('hqAddressState').set(stateModel);
    useUpsert.getField('hqAddressCity').set(cityModel);
  };

  const hoursErrorHandler = (errors: { [key: string]: string[] }): void => {
    Object.keys(errors).forEach(key => {
      const errorMessages = errors[key];
      errorMessages.forEach(message => {
        BaseStore.showAlert(message, 0);
      });
    });
  };

  const handleErrorResponse = error => {
    if (error.response?.data?.errors) {
      hoursErrorHandler(error.response?.data?.errors);
      return;
    }
    if (error?.message) {
      BaseStore.showAlert(error?.message, 0);
    }
  };

  const deleteAllRecords = () => {
    const ids = slideSevenStore.onboardingHoursList && slideSevenStore.onboardingHoursList.map(item => item.id);
    if (ids.length === 0) {
      ModalStore.close();
      return;
    }
    UIStore.setPageLoader(true);
    slideSevenStore
      ?.deleteOnBoarding(ids)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: LocationHoursModel[]) => {
          BaseStore.showAlert('Hours saved successfully!', 0);
          loadOperatingHoursData()
          ModalStore.close();
        },
        error: error => {
          handleErrorResponse(error);
        },
      });
  };

  const handleSave = () => {
    const hoursIdZeroData = vendorLocationHoursStore.updatedHoursData.filter(item => item.id === 0);
    const hoursIdOtherData = vendorLocationHoursStore.updatedHoursData.filter(item => item.id !== 0);

    if (hoursIdZeroData.length === 0 && hoursIdOtherData.length === 0) {
      deleteAllRecords();
      return;
    }

    if (hoursIdOtherData.length === 0) {
      deleteAllRecords();
    }
    const apiCalls = [];

    UIStore.setPageLoader(true);
    apiCalls.push(
      slideSevenStore
        ?.updateVendorOnBoardHours(
          LocationHoursModel.hoursSerializeList(hoursIdOtherData, 1, slideOneAndTwoStore.tempLocationId)
        )
        .pipe(
          mergeMap(() => {
            return forkJoin(
              slideSevenStore?.addVendorOnBoardHours(
                LocationHoursModel.hoursSerializeList(hoursIdZeroData, 1, slideOneAndTwoStore.tempLocationId)
              )
            );
          })
        )
        .pipe(
          catchError(error => {
            handleErrorResponse(error);
            return of(null);
          })
        )
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => UIStore.setPageLoader(false))
        )
        .subscribe(responses => {
          const allSuccessful = responses && responses.every(response => response !== null);
          if (allSuccessful) {
            BaseStore.showAlert('Hours saved successfully!', 0);
            loadOperatingHoursData();
            ModalStore.close();
          }
        })
    );
  }; 

  return (
    <ConfirmNavigate isBlocker={false}>
      <Typography className={classes.heading}>
        If you have an office at this airport, please fill out the fields below:
      </Typography>
      <div className={classes.editorWrapperContainer}>
        <ViewInputControls
          isEditable={true}
          groupInputControls={groupInputControls()}
          onGetField={(fieldKey: string) => useUpsert.getField(fieldKey)}
          onValueChange={(option, fieldKey) => onValueChange(option, fieldKey)}
          field={fieldKey => useUpsert.getField(fieldKey)}
          onSearch={(searchValue: string, fieldKey: string) => onSearch(searchValue, fieldKey)}
          onFocus={fieldKey => onFocus(fieldKey)}
        />
        <div className="mainOperationalHoursWrapper">
          <Typography className={classes.secondaryHeading}>
            Operating Hours
            <Tooltip
              title="Add the operating hours of each day by clicking on Add Operating Hours"
              placement="top-start"
            >
              <InfoIcon className={classes.imageIcon} />
            </Tooltip>
          </Typography>
          <OperatingHoursAddData locationHoursList={slideSevenStore.onboardingHoursList} handleSave={handleSave} />
        </div>
      </div>
    </ConfirmNavigate>
  );
};

export default inject(
  'slideSevenStore',
  'vendorLocationStore',
  'vendorManagementStore',
  'slideOneAndTwoStore',
  'vendorLocationHoursStore'
)(withStyles(styles)(observer(Slide7)));
