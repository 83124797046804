import React, { FC, ReactNode, useEffect, useState } from 'react';
import { MixPanelTrackingEvents, VIEW_MODE, useBaseUpsertComponent } from '@uplink/shared';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import {
  Airports,
  SETTING_ID,
  SettingBaseModel,
  VendorLocationModel,
  ViewInputControls,
  OperationalEssentialsModel,
  SidebarMenus,
  CustomTooltip,
  ENTITY_NAMES,
  ApprovalSettingModel,
  ApprovalDataModel,
  FIELD_NAMES_MAPPING,
  OperationInfoSettingOptionModel,
} from '../../../Shared';
import Questionnaire from '../../../Shared/Components/Questionaire/Questionaire';
import { SettingsStore, VendorManagementStore, VendorLocationStore } from '../../../../Stores';
import { useUnsubscribe } from '@wings-shared/hooks';
import { useStyles } from './OperationalEssentials.styles';
import { inject, observer } from 'mobx-react';
import { fields } from './Fields';
import { IAPISearchFiltersDictionary, IOptionValue, UIStore, IAPIGridRequest, DATE_FORMAT } from '@wings-shared/core';
import { finalize, takeUntil } from 'rxjs/operators';
import { useNavigate, useParams } from 'react-router';
import { RootDataStore, SidebarStore, DetailsEditorWrapper, ConfirmNavigate } from '@uplink-shared/layout';
import { EDITOR_TYPES, IGroupInputControls } from '@uplink-shared/form-controls';
import { forkJoin } from 'rxjs';
import { Box, Button, Tooltip, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import LaunchIcon from '@material-ui/icons/LaunchRounded';
import { AnalyticsStore } from '@uplink-shared/analytics';
import { ApprovalDataStore } from '../../../../Stores/ApprovalData.store';
import { ApprovalSettingsStore } from '../../../../Stores/ApprovalSettings.store';
import { AuthStore } from '@uplink-shared/security';
import { VendorGeneralInfoCard } from '../../../DataExpirationWorkflow';

interface Props {
  vendorLocationStore: VendorLocationStore;
  vendorManagementStore: VendorManagementStore;
  settingsStore: SettingsStore;
  approvalSettingsStore: ApprovalSettingsStore;
  approvalDataStore: ApprovalDataStore;
  params?: { id: number; vendorId: number };
  searchFilters: IAPISearchFiltersDictionary;
}

const OperationalEssentials: FC<Props> = observer(
  ({
    settingsStore,
    vendorLocationStore,
    vendorManagementStore,
    approvalSettingsStore,
    approvalDataStore,
    searchFilters,
  }) => {
    const classes = useStyles();
    const unsubscribe = useUnsubscribe();
    const params = useParams();
    const [ selectedVendorLocation, setSelectedVendorLocation ] = useState(new VendorLocationModel());
    const [ selectedOperationalEssential, setSelectedOperationalEssential ] = useState<OperationalEssentialsModel>(
      new OperationalEssentialsModel()
    );
    const useUpsert = useBaseUpsertComponent<OperationalEssentialsModel>(params, fields, searchFilters);
    const formRef = useUpsert.form;
    const navigate = useNavigate();

    useEffect(() => {
      AnalyticsStore.track(MixPanelTrackingEvents.VENDOR_LOCATION_OPERATIONALESSENTIAL,
        { VendorLocationId:RootDataStore?.locationData?.locationId });
      useUpsert.setViewMode((params.viewMode?.toUpperCase() as VIEW_MODE) || VIEW_MODE.DETAILS);
      loadVendorLocationData();
      SidebarStore.setNavLinks(SidebarMenus(), 'vendor/locations');
      loadInitialData();
      vendorLocationStore.operationalEssentialDataChanged = false;
      vendorLocationStore.isCreditFieldValid = true;
      vendorLocationStore.isOperationTypeFieldExist = true;
    }, []);

    const loadVendorLocationData = () => {
      UIStore.setPageLoader(true);
      vendorLocationStore
        ?.getVendorLocationById(RootDataStore.locationData.locationId)
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => UIStore.setPageLoader(false))
        )
        .subscribe((response: VendorLocationModel) => {
          setSelectedVendorLocation(response);
        });
    };

    const loadInitialData = () => {
      UIStore.setPageLoader(true);
      const request: IAPIGridRequest = {
        filterCollection: JSON.stringify([
          {
            propertyName: 'Vendor.Id',
            propertyValue: AuthStore?.vendorProfile?.id,
          },
        ]),
      };
      forkJoin([
        vendorLocationStore.getLocationOperationalEssentialById(RootDataStore.locationData.locationId),
        vendorLocationStore.getVMSComparison(request),
        vendorLocationStore.getVmsIcaoCode(),
        settingsStore.getSettings(SETTING_ID.SETTINGS_OPERATON_TYPE),
        settingsStore.getSettings(SETTING_ID.SETTINGS_VENDOR_LEVEL),
        settingsStore.getSettings(SETTING_ID.SETTINGS_CERTIFIED_MEMBER_FEE_SCHEDULE),
        settingsStore.getSettings(SETTING_ID.SETTINGS_PAYMENTS_OPTIONS),
        settingsStore.getSettings(SETTING_ID.SETTINGS_CREDIT_AVAILABLE),
        settingsStore.getSettings(SETTING_ID.SETTINGS_MAIN_SERVICE_OFFERED),
        settingsStore.getSettings(SETTING_ID.SETTINGS_PAYMENTS_OPTIONS),
        settingsStore.getSettings(SETTING_ID.SETTINGS_CERTIFIED_MEMBER_FEE_SCHEDULE),
        vendorLocationStore.getCustomers(),
        approvalSettingsStore.getApprovalSettingsByEntityName(ENTITY_NAMES.OperationalEssential),
      ])
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => UIStore.setPageLoader(false))
        )
        .subscribe(
          (
            response: [
              OperationalEssentialsModel,
              VendorLocationModel,
              Airports,
              SettingBaseModel,
              CustomersModel,
              ApprovalSettingModel[]
            ]
          ) => {
            if (!response[0].appliedOperationType) {
              vendorLocationStore.isOperationTypeFieldExist = false;
            }
            setSelectedOperationalEssential(response[0]);
            getApprovalDataAndSetFormValues(response[0]);
          }
        );
    };

    const getApprovalDataAndSetFormValues = (response: OperationalEssentialsModel) => {
      approvalDataStore
        .getApprovalData(ENTITY_NAMES.OperationalEssential, response.id)
        .subscribe((res: ApprovalDataModel[]) => {
          if (response.success === false) {
            useUpsert.setFormValues(new OperationalEssentialsModel());
          } else {
            approvalDataStore.oldEntityData = JSON.parse(
              JSON.stringify(
                response.serialize(
                  parseInt(RootDataStore.locationData.locationId),
                  parseInt(response?.appliedOperationType?.operationTypeId)
                )
              )
            );
            useUpsert.setFormValues(response);
            setOperationTypeValue(res, response);
            setFieldValue(res, response, 'coordinatingOffice', vendorLocationStore?.vendorLocationList);
            setFieldValue(
              res,
              response,
              'provideCoordinationFor',
              vendorLocationStore.getOperationalEssentialSettingOptions<VendorLocationModel>(
                vendorLocationStore?.vendorLocationList,
                'vendorLocation'
              )
            );
            setFieldValue(
              res,
              response,
              'commsCopyFor',
              vendorLocationStore.getOperationalEssentialSettingOptions<VendorLocationModel>(
                vendorLocationStore?.vendorLocationList,
                'vendorLocation'
              )
            );
            setFieldValue(
              res,
              response,
              'appliedCreditAvailable',
              vendorLocationStore.getOperationalEssentialSettingOptions<SettingBaseModel>(
                settingsStore?.creditAvailable,
                'creditAvailable'
              )
            );
            setFieldValue(
              res,
              response,
              'appliedMainServicesOffered',
              vendorLocationStore.getOperationalEssentialSettingOptions<SettingBaseModel>(
                settingsStore?.mainServicesOffered,
                'mainServicesOffered'
              )
            );
            setFieldValue(
              res,
              response,
              'appliedPaymentOptions',
              vendorLocationStore.getOperationalEssentialSettingOptions<SettingBaseModel>(
                settingsStore?.paymentOptions,
                'paymentOptions'
              )
            );
            setFieldValue(res, response, 'airToGroundFrequency');

            setFieldValue(res, response, 'locationAirfield');
            setFieldValue(res, response, 'managerName');
            setFieldValue(res, response, 'asstManagerName');
            setFieldValue(res, response, 'startDate');
            setFieldValue(res, response, 'endDate');

            const twoYearFromNow = new Date(useUpsert.getField('startDate').value);
            calcFutureTwoYearDate(twoYearFromNow);
            isProprietary();
          }
        });
    };

    const setOperationTypeValue = (res: ApprovalDataModel[], response: OperationalEssentialsModel) => {
      const fieldName = 'appliedOperationType';
      const options: OperationInfoSettingOptionModel[] = vendorLocationStore.getOperationalEssentialSettingOptions<
        SettingBaseModel
      >(settingsStore?.operationType, 'operationType');

      const mapping = FIELD_NAMES_MAPPING.find(m => m.key == fieldName);
      const fieldApprovalData = res.find(k => k.fieldName == mapping.apiFieldName);
      let data = response[fieldName];
      if (fieldApprovalData) {
        data = JSON.parse(fieldApprovalData.newValue);
        const mappedOption: OperationInfoSettingOptionModel[] = [];
        data.forEach(element => {
          const selectedData = options.find(
            a => a[mapping.refrentialEntityName].id == element[mapping.refrentialFieldName]
          );
          mappedOption.push(new OperationInfoSettingOptionModel(selectedData));
        });
        data = mappedOption[0];
      }
      useUpsert.getField(fieldName).set(data);
      useUpsert.getField('appliedOperationTypeId').set(data?.operationType?.id);
      if (data) {
        vendorLocationStore.isOperationTypeFieldExist = true;
      } else {
        vendorLocationStore.isOperationTypeFieldExist = false;
      }
    };

    const setFieldValue = (
      res: ApprovalDataModel[],
      response: OperationalEssentialsModel,
      filedName: string,
      options: OperationInfoSettingOptionModel[]
    ) => {
      const mapping = FIELD_NAMES_MAPPING.find(m => m.key == filedName);
      const fieldApprovalData = res.find(k => k.fieldName == mapping.apiFieldName);
      let data = response[filedName];
      if (fieldApprovalData) {
        data = mapping?.isJSONObject ? JSON.parse(fieldApprovalData.newValue) : fieldApprovalData.newValue;

        if (mapping?.isListType) {
          const mappedOption: OperationInfoSettingOptionModel[] = [];
          data.forEach(element => {
            const selectedData = options.find(
              a => a[mapping.refrentialEntityName].id == element[mapping.refrentialFieldName]
            );
            mappedOption.push(new OperationInfoSettingOptionModel(selectedData));
          });
          data = mappedOption;
        }
        if (mapping?.isSendReferenceId) {
          data = options.find(a => a.id == Number(fieldApprovalData.newValue));
        }
      }
      useUpsert.getField(filedName).set(data);
    };

    const errorHandler = (errors: object, id): void => {
      Object.values(errors)?.forEach(errorMessage => useUpsert.showAlert(errorMessage[0], id));
    };

    const onValueChange = (value: IOptionValue, fieldKey: string): void => {
      useUpsert.getField(fieldKey).set(value);
      vendorLocationStore.operationalEssentialDataChanged = true;
      switch (fieldKey) {
        case 'customers':
          isProprietary();
          break;
        case 'managerName':
        case 'asstManagerName':
          if (!value) {
            return;
          }
          const regex = /^[^ ]/;
          if (!regex.test(value.toString())) {
            useUpsert.showAlert('First Character cannot be space', '0');
            vendorLocationStore.operationalEssentialDataChanged = false;
          } else {
            vendorLocationStore.operationalEssentialDataChanged = true;
          }
          break;
        case 'startDate':
          calcFutureTwoYearDate(new Date(value));
          break;

        case 'appliedCreditAvailable':
          vendorLocationStore.isCreditFieldValid = true;
          const appliedCreditAvailable = useUpsert.getField('appliedCreditAvailable')?.value;
          if (appliedCreditAvailable && appliedCreditAvailable.find(data => data.creditAvailable.id === 4)) {
            if (appliedCreditAvailable && appliedCreditAvailable.find(data => data.creditAvailable.id !== 4)) {
              vendorLocationStore.isCreditFieldValid = false;
            }
          }
          break;
        default:
          useUpsert.getField(fieldKey).set(value);
      }
    };

    const isProprietary = (): boolean => {
      const isProprietaryValue = useUpsert.getField('isProprietary')?.value;
      if (Boolean(isProprietaryValue)) {
        useUpsert.setFormRules('customers', true);
      } else {
        useUpsert.setFormRules('customers', false);
      }
      return !isProprietaryValue;
    };

    const onSearch = (searchValue: string, fieldKey: string): void => {
      const airportRequest: IAPIGridRequest = {
        searchCollection: JSON.stringify([
          { propertyName: 'Name', propertyValue: searchValue },
          { propertyName: 'ICAOCode.Code', propertyValue: searchValue, operator: 'or' },
          { propertyName: 'UWACode', propertyValue: searchValue, operator: 'or' },
          { propertyName: 'FAACode', propertyValue: searchValue, operator: 'or' },
          { propertyName: 'IATACode', propertyValue: searchValue, operator: 'or' },
          { propertyName: 'RegionalCode', propertyValue: searchValue, operator: 'or' },
          { propertyName: 'DisplayCode', propertyValue: searchValue, operator: 'or' },
        ]),
      };
      const locationRequest: IAPIGridRequest = {
        searchCollection: JSON.stringify([
          { propertyName: 'Name', propertyValue: searchValue },
          { propertyName: 'AirportReference.ICAOCode', propertyValue: searchValue, operator: 'or' },
          { propertyName: 'AirportReference.UWACode', propertyValue: searchValue, operator: 'or' },
          { propertyName: 'AirportReference.FAACode', propertyValue: searchValue, operator: 'or' },
          { propertyName: 'AirportReference.IATACode', propertyValue: searchValue, operator: 'or' },
          { propertyName: 'AirportReference.RegionalCode', propertyValue: searchValue, operator: 'or' },
          { propertyName: 'AirportReference.DisplayCode', propertyValue: searchValue, operator: 'or' },
          {
            propertyName: 'VendorLocationCityReference.CityReference.Name',
            propertyValue: searchValue,
            operator: 'or',
          },
          {
            propertyName: 'VendorLocationCityReference.CityReference.Code',
            propertyValue: searchValue,
            operator: 'or',
          },
        ]),
      };
      switch (fieldKey) {
        case 'coordinatingOffice':
          vendorLocationStore.getVMSComparison(locationRequest).subscribe();
          break;
        case 'agentDispatchedFrom':
          vendorLocationStore.getVmsIcaoCode(airportRequest).subscribe();
          break;
        case 'provideCoordinationFor':
          vendorLocationStore.getVMSComparison(locationRequest).subscribe();
          break;
        case 'commsCopyFor':
          vendorLocationStore.getVMSComparison(locationRequest).subscribe();
          break;
        case 'creditProvidedBy':
          vendorLocationStore.getVMSComparison(locationRequest).subscribe();
          break;
        case 'creditProvidedFor':
          vendorLocationStore.getVMSComparison(locationRequest).subscribe();
          break;
        default:
          break;
      }
      return;
    };

    const profileEndAdornment = (): ReactNode => {
      return (
        <LaunchIcon
          onClick={() => ModalStore.open(<Questionnaire useUpsert={useUpsert} settingsStore={settingsStore} />)}
          className={classes.buttonStyle}
        />
      );
    };

    const calcFutureTwoYearDate = startDateValue => {
      startDateValue.setFullYear(startDateValue.getFullYear() + 2);
      useUpsert.getField('complianceDiligenceDueDate').set(startDateValue);
    };

    const groupInputControls = (): IGroupInputControls[] => {
      return [
        {
          title: 'Operational Information:',
          inputControls: [
            {
              fieldKey: 'id',
              type: EDITOR_TYPES.TEXT_FIELD,
              isHidden: true,
            },
            {
              fieldKey: 'appliedOperationTypeId',
              type: EDITOR_TYPES.TEXT_FIELD,
              isHidden: true,
            },
            {
              fieldKey: 'appliedOperationType',
              type: EDITOR_TYPES.CUSTOM_COMPONENT,
              endAdormentValue: profileEndAdornment(),
              options: vendorLocationStore.getOperationalEssentialSettingOptions<SettingBaseModel>(
                settingsStore?.operationType,
                'operationType'
              ),
              customErrorMessage: !vendorLocationStore.isOperationTypeFieldExist && 'This Operation Type* is required',
              isCustomInputDisable: true,
              multiple: true,
              isHalfFlex: true,
              tooltipText: 'Data is pending for admin approval',
              customLabel: field => {
                return (
                  <div className={classes.flexIcon}>
                    <span>Operation Type*</span>
                    <Tooltip title="To change the operation type click the link below" placement="top">
                      <InfoIcon className={classes?.imageIcon} />
                    </Tooltip>
                  </div>
                );
              },
            },
            {
              fieldKey: 'coordinatingOffice',
              type: EDITOR_TYPES.DROPDOWN,
              options: vendorLocationStore?.vendorLocationList,
              tooltipText: 'Data is pending for admin approval',
              isHalfFlex: true,
            },
            {
              fieldKey: 'provideCoordinationFor',
              type: EDITOR_TYPES.AUTO_COMPLETE,
              options: vendorLocationStore.getOperationalEssentialSettingOptions<VendorLocationModel>(
                vendorLocationStore?.vendorLocationList,
                'vendorLocation'
              ),
              multiple: true,
              tooltipText: 'Data is pending for admin approval',
              disableCloseOnSelect: true,
            },
            {
              fieldKey: 'commsCopyFor',
              type: EDITOR_TYPES.DROPDOWN,
              options: vendorLocationStore.getOperationalEssentialSettingOptions<VendorLocationModel>(
                vendorLocationStore?.vendorLocationList,
                'vendorLocation'
              ),
              multiple: true,
              isHidden: true,
              tooltipText: 'Data is pending for admin approval',
              disableCloseOnSelect: true,
            },
            {
              fieldKey: 'startDate',
              type: EDITOR_TYPES.DATE,
              maxDate: useUpsert.getField('endDate')?.value || null,
              dateTimeFormat: DATE_FORMAT.DATE_PICKER_FORMAT,
              allowKeyboardInput: false,
              tooltipText: 'Data is pending for admin approval',
            },
            {
              fieldKey: 'endDate',
              type: EDITOR_TYPES.DATE,
              minDate: useUpsert.getField('startDate')?.value || null,
              dateTimeFormat: DATE_FORMAT.DATE_PICKER_FORMAT,
              allowKeyboardInput: false,
              tooltipText: 'Data is pending for admin approval',
            },
            {
              fieldKey: 'appliedCreditAvailable',
              type: EDITOR_TYPES.AUTO_COMPLETE,
              options: vendorLocationStore.getOperationalEssentialSettingOptions<SettingBaseModel>(
                settingsStore?.creditAvailable,
                'creditAvailable'
              ),
              multiple: true,
              disableCloseOnSelect: true,
              isHalfFlex: true,
              tooltipText: 'Data is pending for admin approval',
              customErrorMessage: !vendorLocationStore.isCreditFieldValid
                ? 'To choose "None" you must remove other values.'
                : '',
            },
            {
              fieldKey: 'appliedMainServicesOffered',
              type: EDITOR_TYPES.AUTO_COMPLETE,
              options: vendorLocationStore.getOperationalEssentialSettingOptions<SettingBaseModel>(
                settingsStore?.mainServicesOffered,
                'mainServicesOffered'
              ),
              multiple: true,
              disableCloseOnSelect: true,
              isHalfFlex: true,
            },
            {
              fieldKey: 'appliedPaymentOptions',
              type: EDITOR_TYPES.AUTO_COMPLETE,
              options: vendorLocationStore.getOperationalEssentialSettingOptions<SettingBaseModel>(
                settingsStore?.paymentOptions,
                'paymentOptions'
              ),
              multiple: true,
              disableCloseOnSelect: true,
              isHalfFlex: true,
            },
            {
              fieldKey: 'airToGroundFrequency',
              type: EDITOR_TYPES.TEXT_FIELD,
              isHalfFlex: true,
              tooltipText: 'Data is pending for admin approval',
            },
            {
              fieldKey: 'locationAirfield',
              type: EDITOR_TYPES.TEXT_FIELD,
              tooltipText: 'Data is pending for admin approval',
            },
            {
              fieldKey: 'managerName',
              type: EDITOR_TYPES.TEXT_FIELD,
              tooltipText: 'Data is pending for admin approval',
            },
            {
              fieldKey: 'asstManagerName',
              type: EDITOR_TYPES.TEXT_FIELD,
              tooltipText: 'Data is pending for admin approval',
            },

            {
              fieldKey: 'contractRenewalDate',
              type: EDITOR_TYPES.DATE,
              dateTimeFormat: DATE_FORMAT.DATE_PICKER_FORMAT,
              allowKeyboardInput: false,
              isDisabled: true,
              isNonEditable: true,
            },
            {
              fieldKey: 'certifiedMemberFee',
              type: EDITOR_TYPES.TEXT_FIELD,
              isDisabled: true,
              isNonEditable: true,
            },
            {
              fieldKey: 'certifiedMemberFeeSchedule',
              type: EDITOR_TYPES.DROPDOWN,
              options: settingsStore?.certifiedMemberFeeSchedule,
              isDisabled: true,
              isNonEditable: true,
            },
            {
              fieldKey: 'vendorLevel',
              type: EDITOR_TYPES.DROPDOWN,
              options: settingsStore?.vendorLevel,
              isNonEditable: true,
            },
            {
              fieldKey: 'complianceDiligenceDueDate',
              type: EDITOR_TYPES.DATE,
              dateTimeFormat: DATE_FORMAT.DATE_PICKER_FORMAT,
              allowKeyboardInput: false,
              isDisabled: true,
              isNonEditable: true,
              isHalfFlex: true,
            },
            {
              fieldKey: 'isProprietary',
              type: EDITOR_TYPES.SELECT_CONTROL,
              isBoolean: true,
              excludeEmptyOption: true,
              isDisabled: true,
              isNonEditable: true,
            },
            {
              fieldKey: 'customers',
              type: EDITOR_TYPES.DROPDOWN,
              options: vendorLocationStore?.customersList,
              multiple: true,
              isDisabled: isProprietary(),
              isNonEditable: true,
              isHalfFlex: true,
            },
          ],
        },
      ];
    };

    const headerActions = (): ReactNode => {
      return (
        <>
          <Typography>
            <CustomTooltip title={`${selectedVendorLocation.name} - ${selectedVendorLocation.headerLabel()}`} />
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <div className={`${classes.defaultButton}`}>
              <Button color="primary" variant="outlined" onClick={() => navigate('/vendor/locations')} size="large">
                Cancel
              </Button>
            </div>
            <div className={`${classes.primaryButton} ${classes.defaultButton}`}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  const request = new OperationalEssentialsModel({ ...useUpsert.form.values() });
                  if (request.id == 0) {
                    upsertVendorLocationOperationalEssential();
                    return;
                  }
                  upsertApprovalDataList();
                }}
                size="large"
                disabled={
                  !formRef.isValid ||
                  !vendorLocationStore.operationalEssentialDataChanged ||
                  formRef.hasError ||
                  !vendorLocationStore.isCreditFieldValid ||
                  !vendorLocationStore.isOperationTypeFieldExist
                }
              >
                Save
              </Button>
            </div>
          </Box>
        </>
      );
    };

    const upsertVendorLocationOperationalEssential = (): void => {
      const request = new OperationalEssentialsModel({ ...useUpsert.form.values() });
      UIStore.setPageLoader(true);
      vendorLocationStore
        ?.upsertVendorLocationOperationalEssential(
          request.serialize(
            parseInt(RootDataStore.locationData.locationId),
            parseInt(useUpsert.getField('appliedOperationTypeId').value)
          )
        )
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => UIStore.setPageLoader(false))
        )
        .subscribe({
          next: (response: OperationalEssentialsModel) => {
            useUpsert.form.reset();
            getApprovalDataAndSetFormValues(response);
            vendorLocationStore.operationalEssentialDataChanged = false;
          },
          error: error => {
            errorHandler(error.response.data.errors, request.id.toString());
          },
        });
    };

    const upsertApprovalDataList = (): void => {
      const request = new OperationalEssentialsModel({ ...useUpsert.form.values() });
      UIStore.setPageLoader(true);
      const dataReq = request.serialize(
        parseInt(RootDataStore.locationData.locationId),
        parseInt(useUpsert.getField('appliedOperationTypeId').value)
      );
      approvalDataStore
        .upsertApprovalData(
          ApprovalDataModel.serializeList(
            ENTITY_NAMES.OperationalEssential,
            dataReq,
            approvalDataStore.oldEntityData,
            approvalDataStore.oldEntityData.id,
            approvalDataStore
          )
        )
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => UIStore.setPageLoader(false))
        )
        .subscribe({
          next: (response: ApprovalDataModel[]) => {
            useUpsert.form.reset();
            getApprovalDataAndSetFormValues(selectedOperationalEssential);
            vendorLocationStore.operationalEssentialDataChanged = false;
          },
          error: error => {
            errorHandler(error.response.data.errors, request.id.toString());
          },
        });
    };
    const onFocus = (fieldKey: string): void => {
      const request: IAPIGridRequest = {
        filterCollection: JSON.stringify([
          {
            propertyName: 'Vendor.Id',
            propertyValue: AuthStore?.vendorProfile?.id,
          },
        ]),
      };
      switch (fieldKey) {
        case 'coordinatingOffice':
          vendorLocationStore.getVMSComparison(request).subscribe();
          break;
        case 'commsCopyFor':
          vendorLocationStore.getVMSComparison().subscribe();
          break;
        case 'creditProvidedBy':
          vendorLocationStore.getVMSComparison().subscribe();
          break;
        case 'creditProvidedFor':
          vendorLocationStore.getVMSComparison().subscribe();
          break;
        case 'provideCoordinationFor':
          vendorLocationStore.getVMSComparison(request).subscribe();
          break;
      }
    };

    return (
      <Box>
        <VendorGeneralInfoCard/>
        <ConfirmNavigate isBlocker={vendorLocationStore.operationalEssentialDataChanged}>
          <DetailsEditorWrapper
            headerActions={headerActions()}
            isEditMode={true}
            classes={{ headerActions: classes.headerActions }}
          >
            <ViewInputControls
              isEditable={true}
              groupInputControls={groupInputControls()}
              onGetField={(fieldKey: string) => useUpsert.getField(fieldKey)}
              onValueChange={(option, fieldKey) => onValueChange(option, fieldKey)}
              field={fieldKey => useUpsert.getField(fieldKey)}
              onSearch={(searchValue: string, fieldKey: string) => onSearch(searchValue, fieldKey)}
              onFocus={fieldKey => onFocus(fieldKey)}
            />
          </DetailsEditorWrapper>
        </ConfirmNavigate>
      </Box>
    );
  }
);
export default inject(
  'settingsStore',
  'vendorLocationStore',
  'vendorManagementStore',
  'approvalSettingsStore',
  'approvalDataStore'
)(OperationalEssentials);
