import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  warningIcon: {
    fontSize: 60,
    color: '#F2C12C',
  },
  dialog: {
    padding: '20px',
    textAlign: 'center',
    '& .MuiDialogActions-root': {
      justifyContent: 'center',
    },
    '& .MuiButton-label':{
      textDecoration: 'none'
    },
  },
  title: {
    fontFamily: 'Open Sans',
    fontSize: '20px',
    fontWeight: 900,
    lineHeight: '27px',
    letterSpacing: '0em',
    textAlign: 'center',
    padding: '0px',
  },
  content: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '19px',
    letterSpacing: '0em',
    textAlign: 'center',
  },
  cancelButton: {
    minWidth:'100px',
    textTransform: 'capitalize',
    color: '#1976D2',
    '&:hover' : {
      textDecoration: 'underline',
      color: '#63A4FF'
    },
    '& .MuiButton-root:hover': {
      backgroundColor: 'FFFFFF !important',
      color: '#63A4FF',
    },
  },
  yesButton: {
    backgroundColor: '#1976D2',
    textTransform: 'capitalize',
    marginTop: '20px',
    '& .MuiButton-contained:hover': {
      backgroundColor: '#63A4FF',
      boxShadow: 'none',
    },
  },
  defaultButton: {
    '& button': {
      padding: '4px 10px',
      height: '40px',
      fontSize: '14px',
      fontWeight: '400',
      minWidth: '100px',
      textTransform: 'capitalize',
      color: '#1976D2',
      textDecoration: 'none',
      '& .MuiButton-label': {
        textDecoration: 'none',
      },
      '&:hover': {
        color: '#63A4FF',
      }
    },
    '& button:hover': {
      color: '#63A4FF',
      textDecoration: 'none',
    },
  },
  primaryButton: {
    marginLeft:'10px !important',
    '& button': {
      background: '#1976D2',
      color: '#fff',
      minWidth: '100px',
      height: '40px',
    },
    '& button:disabled': {
      background: '#afafaf',
      border: 'none',
      color: 'white',
    },
    '& button:hover': {
      backgroundColor: '#63A4FF',
      color: '#FFFFFF'
    },
  },
});
