import { CoreModel, ISelectOption, modelProtection } from '@wings-shared/core';
import { SettingBaseModel } from './SettingBase.model';
import { VendorManagmentModel } from './VendorManagment.model';
import { StatusBaseModel } from './StatusBase.model';
import { VendorLocationModel } from './VendorLocation.model';
import { IAPIResponseVendorDocument } from '../Interfaces/Response/API-Response-VendorDocument';
import { IAPIRequestVendorDocument } from '../Interfaces/Request/API-Request-VendorDocument';
import { AuthStore } from '@uplink-shared/security';

@modelProtection
export class VendorDocumentModel extends CoreModel implements ISelectOption {
  id: number = 0;
  vendor?: VendorManagmentModel = new VendorManagmentModel();
  name: SettingBaseModel = new SettingBaseModel();
  otherName: string = '';
  documentUri: string = '';
  status: StatusBaseModel = new StatusBaseModel();
  statusId: number;
  vendorId?: number;
  vendorLocationId?: number;
  documentNameId: number;
  startDate: Date = new Date();
  endDate?: Date = null;
  comment?: string = null;
  lastUpdated: string = AuthStore.user?.name || '';
  vendorLocation?: VendorLocationModel = new VendorLocationModel();

  constructor(data?: Partial<VendorDocumentModel>) {
    super(data);
    Object.assign(this, data);
  }
  // required in auto complete
  public get label(): string {
    return this.name.name;
  }

  public get value(): string | number {
    return this.id;
  }

  public serialize(
    documentUri: string,
    vendorId: number,
    locationId: number,
    archiveStatusId: number
  ): IAPIRequestVendorDocument {
    return {
      userId: '',
      id: this.id || 0,
      documentUri: documentUri ? documentUri : this.documentUri,
      statusId: archiveStatusId != 0 ? archiveStatusId : this.status?.id || 1,
      documentNameId: this.documentNameId ? this.documentNameId : this.name.id,
      vendorId: vendorId,
      otherName: this.otherName?.replace(/^\s*\s*$/, '') || null,
      startDate: this.startDate,
      endDate: this.endDate || null,
      comment: this.comment || null,
      lastUpdated: AuthStore.user?.name,
      vendorLocationId: locationId,
    };
  }

  static deserialize(apiData: IAPIResponseVendorDocument): VendorDocumentModel {
    if (!apiData) {
      return new VendorDocumentModel();
    }
    const data: Partial<VendorDocumentModel> = {
      ...apiData,
      vendor: VendorManagmentModel.deserialize(apiData?.vendor),
      vendorLocation: VendorLocationModel.deserialize(apiData?.vendorLocation),
      name: SettingBaseModel.deserialize(apiData.documentName),
      status: StatusBaseModel.deserialize(apiData.status),
      documentUri: apiData.documentUri,
      otherName: apiData.otherName,
      startDate: apiData.startDate,
      endDate: apiData.endDate,
      comment: apiData.comment,
      lastUpdated: apiData.lastUpdated,
    };
    return new VendorDocumentModel(data);
  }

  static deserializeList(apiDataList: IAPIResponseVendorDocument[]): VendorDocumentModel[] {
    return apiDataList ? apiDataList.map(apiData => VendorDocumentModel.deserialize(apiData)) : [];
  }
}
