import { CoreModel, ISelectOption, modelProtection } from '@wings-shared/core';
import { CountryModel } from '@uplink/shared';
import { SettingBaseModel } from './SettingBase.model';

@modelProtection
export class CabotageSettingOptionModel extends CoreModel implements ISelectOption {
  userId: string = '';
  appliedRegionCabotageExemptionId: number = 0;
  appliedCountryCabotageExemptionId: number = 0;
  cabotageEnforcedForFARTypeId: number = 0;
  region: SettingBaseModel = new SettingBaseModel();
  country: CountryModel = new CountryModel();
  farType: SettingBaseModel = new SettingBaseModel();

  constructor(data?: Partial<CabotageSettingOptionModel>) {
    super(data);
    Object.assign(this, data);
  }

  static deserialize(apiData: CabotageSettingOptionModel): CabotageSettingOptionModel {
    if (!apiData) {
      return new CabotageSettingOptionModel();
    }
    const data: Partial<CabotageSettingOptionModel> = {
      ...apiData,
      country: CountryModel.deserialize(apiData.country),
      region: SettingBaseModel.deserialize({
        ...apiData.region,
        id: apiData.regionId,
        code: apiData.regionCode,
        name: apiData.regionName,
      }),
      farType: SettingBaseModel.deserialize({
        ...apiData.farType,
        id: apiData.permitFarTypeId,
        code: apiData.cappsCode,
        name: apiData.name,
      }),
    };
    return new CabotageSettingOptionModel(data);
  }

  static deserializeList(apiDataList: CabotageSettingOptionModel[]): CabotageSettingOptionModel[] {
    return apiDataList ? apiDataList.map((apiData: any) => CabotageSettingOptionModel.deserialize(apiData)) : [];
  }

  public serialize() {
    return {
      id: this.id || 0,
      userId: this.userId,
    };
  }

  public get label(): string {
    if (this.country?.label) return this.country.label;
    if (this.region.label) return this.region.label;
    if (this.farType.label) return this.farType.label;
    return '';
  }

  public get value(): string | number {
    if (this.country?.value) return this.country.value;
    if (this.region?.value) return this.region.value;
    if (this.farType?.value) return this.farType.value;
    return '';
  }
}
