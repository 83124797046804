import { CoreModel, ISelectOption, modelProtection } from '@wings-shared/core';
import { SettingBaseModel } from './SettingBase.model';
import { RootDataStore } from '@uplink-shared/layout';

@modelProtection
export class AirsideLogisticsModel extends CoreModel implements ISelectOption {
  userId: string;
  id: number;
  vendorLocationId: number = 0;
  walkingDistance: SettingBaseModel = new SettingBaseModel();
  shuttleAvailability: number = 0;
  isShuttleAdditionalFee: number = 0;
  additionalFee: string = '';

  constructor(data?: Partial<AirsideLogisticsModel>) {
    super(data);
    Object.assign(this, data);
  }

  static deserialize(apiData: AirsideLogisticsModel): AirsideLogisticsModel {
    if (!apiData) {
      return new AirsideLogisticsModel();
    }
    const data: Partial<AirsideLogisticsModel> = {
      ...apiData,
      walkingDistance: AirsideLogisticsModel.walkingDistanceOptions().filter(item => {
        return item.name === apiData?.walkingDistance;
      })[0],
      shuttleAvailability: apiData.shuttleAvailability,
      isShuttleAdditionalFee: apiData.isShuttleAdditionalFee,
      additionalFee: apiData.additionalFee,
    };
    return new AirsideLogisticsModel(data);
  }

  static deserializeList(apiDataList: AirsideLogisticsModel[]): AirsideLogisticsModel[] {
    return apiDataList ? apiDataList.map((apiData: any) => AirsideLogisticsModel.deserialize(apiData)) : [];
  }

  public serialize() {
    return {
      id: this.id || 0,
      userId: this.userId || '',
      vendorLocationId: this.vendorLocationId || RootDataStore.locationData.locationId,
      walkingDistance: this.walkingDistance?.name || null,
      shuttleAvailability: this.shuttleAvailability === 1 ? 'Yes' : 'No',
      isShuttleAdditionalFee: this.isShuttleAdditionalFee === 3 ? 'Yes' : 'No',
      additionalFee: this.additionalFee?.toString()?.replace(/\s+/gi, ' ') || '',
    };
  }

  public static walkingDistanceOptions() {
    return SettingBaseModel.deserializeList([
      { id: 1, name: 'Less than 5 min walk (300m approx.)' },
      { id: 2, name: 'About 5-10 min walk (500m approx.)' },
      { id: 3, name: 'About 10-15 min walk (1000m approx.)' },
      { id: 4, name: 'More than a 15 min walk (more than 1000m)' },
    ]);
  }

  public get label(): string {
    return this.name;
  }

  public get value(): string | number {
    return this.id;
  }
}
