import { createStyles, Dialog, Theme } from '@material-ui/core';
import { Height } from '@material-ui/icons';

export const styles = createStyles((theme:Theme) => ({
  headerActions: {
    justifyContent: 'space-between',
    borderBottom: '1px solid #D3D3D3',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    '& button':{
      width: '140px',
      // marginRight: theme.spacing(1),
    },
    '& h5':{
      fontSize: '1.625rem',
      // color: theme.palette.grey.A700
    }
  },
  container:{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  customHeight: {
    height: 'calc(100vh - 290px)'
  },
  editorWrapperContainer: {
    overflow: 'auto',
    '& [class*="ViewInputControl-checkboxRoot-"]':{
      marginLeft:'0'
    },
    '& .makeStyles-flexRow-32':{
      paddingBottom:'0px !important' 
    }
  },
  gridHeight:{
    paddingBottom:'70px'
  },
  defaultButton: {
    marginLeft:'16px',
    '& button':{
      border: '1px solid #1976D2',
      padding:'4px 10px',
      height: '40px',
      borderRadius: '4px',
      fontSize:'14px',
      fontWeight: '400',
      minWidth:'140px',
      textTransform:'capitalize',
      color:'#1976D2'
    }
  },
  customButton: {
    '& button':{
      border: '1px solid #1976D2',
      padding:'4px 10px',
      height: '40px',
      borderRadius: '4px',
      fontSize:'14px',
      fontWeight: '400',
      width:'140px',
      textTransform:'capitalize',
      color:'#1976D2'
    }
  },
  primaryButton:{
    '& button':{
      background: '#1976D2',
      color:'#fff'
    },
    '& :hover':{
      backgroundColor:'rgba(99, 164, 255, 1)!important',
      borderColor:'rgba(99, 164, 255, 1)'
    },
    '& button:disabled':{
      background: '#afafaf',
      border:'none'
    }
  },

  RadioButton: {
    gap:'8px',
  },

  fileBox:{
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    backgroundColor: '#F1F1F1',
    borderRadius: '4px',
    alignItems: 'center',
    margin: theme.spacing(1),
    marginTop: theme.spacing(3),
    '& p:first-child':{
      color: '#1976D2',
      '&:hover': {
        opacity: 1,
        cursor: 'pointer',
      },
    },
  },
  flexIcon:{
    display: 'flex',
  },
  imageIcon: {
    height: '15px',
    width: '15px',
    cursor: 'pointer',
    color: '#1976D2',
    pointerEvents: 'all',
    marginLeft: theme.spacing(.5),
    '& svg':{
      fontSize:'16px',
    }
  },
  DialogTitle:{
    height:'38px'
  },

  DilogSize:{
    width:'800px'
  },
  UserNameField: {
    '& .MuiInputBase-root': {
      fontSize: '15px', 
      height: '50px', 
    },
    '& .MuiInputLabel-root': {
      fontSize: '12px',
      fontWeight: 600,
      height: '16px',
    },
    width: '100%',
    marginTop: theme.spacing(2), 
  },
  dialogButton: {
    width: '100px',
    height: '40px',
    fontSize: '1rem',  
    padding: '12px 24px',
    minWidth: '120px', 
    borderRadius: '6px',
    margin: theme.spacing(1),
  }
}));