import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) => {
  return {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: 10,
      alignItems: 'center',
      height: 40,
      color: theme.palette.text.primary,
    },
    icon: {
      flexShrink: 0,
      width: 10,
      height: 14,
    },
    label: {
      fontSize: 14,
      fontWeight: 400,
      color: theme.palette.text.primary,
    },
    control: {
      flexShrink: 0,
      '& .MuiIconButton-root': {
        color: theme.palette.text.primary,
      },
    },
    hiddenItemBtn: {
      color: '#d7d7d7',
    },
  };
});
