import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  arrivalSelectDropdown: {
    '& [class *="ViewInputControl-"]':{
      paddingBottom:'0'
    },
    '& [class *="MuiInputLabel-"]':{
      fontSize:'14px'
    }
  }
}));
