import { CoreModel, StatusTypeModel, modelProtection } from '@wings-shared/core';
import { ServiceItemModel } from './ServiceItem.model';
import { CurrencyModel } from './Currency.model';
import { VendorManagmentModel } from './VendorManagment.model';
import { IAPIVMSVendorComparison, IAPIVmsVendorStatusTable } from '../Interfaces';
import { ServiceItemPricingLocationModel } from './ServiceItemPricingLocation.model';
import { HandlingFeeModel } from './HanglingFee.model';
import { UOMModel } from './Uom.model';
import { ParameterModel } from './Parameter.model';
import { AuthStore } from '@uplink-shared/security';
import { RootDataStore } from '@uplink-shared/layout';

@modelProtection
export class ServiceItemPricingModel extends CoreModel {
  public id: number = 0;
  public approvalId: number = 0;
  public serviceItemId: number = 0;
  public serviceItem: ServiceItemModel = new ServiceItemModel();
  public isCommissionable: boolean = false;
  public isDirectService: boolean = true;
  public thirdPartyVendorComment: string = '';
  public isVariablePricing: boolean = false;
  public handlingFeeId: number = 0;
  public handlingFee: HandlingFeeModel = new HandlingFeeModel();
  public priceDataUnavailable: boolean = false;
  public parameterId: number = 0;
  public parameter: ParameterModel = new ParameterModel();
  public lowerLimit: number = null;
  public upperLimit: number = null;
  public price: number = null;
  public currencyId: number = 0;
  public currency: CurrencyModel = new CurrencyModel();
  public unitId: number = 0;
  public uom: UOMModel = new UOMModel();
  public comment: string = '';
  public validFrom: Date = new Date();
  public validTo: Date = new Date();
  public status: StatusTypeModel = new StatusTypeModel();
  public statusId: number = 0;
  public vendorId: number = 0;
  public vendor: VendorManagmentModel = new VendorManagmentModel();
  public vendorLocation: ServiceItemPricingLocationModel[] = [];
  public selected: boolean = false;
  ServiceItemPricingLocations: number[] = [];

  constructor(data?: Partial<ServiceItemPricingModel>) {
    super(data);
    Object.assign(this, data);
  }

  static deserialize(apiData: ServiceItemPricingModel): ServiceItemPricingModel {
    if (!apiData) {
      return new ServiceItemPricingModel();
    }
    const data: Partial<ServiceItemPricingModel> = {
      ...apiData,
      serviceItem: ServiceItemModel.deserialize(apiData.serviceItem),
      currency: CurrencyModel.deserialize(apiData.currency),
      handlingFee: HandlingFeeModel.deserialize(apiData.handlingFee),
      parameter: ParameterModel.deserialize(apiData.parameter),
      status: StatusTypeModel.deserialize(apiData.status as IAPIVmsVendorStatusTable),
      uom: UOMModel.deserialize(apiData.uom),
      vendor: VendorManagmentModel.deserialize(apiData.vendor as IAPIVMSVendorComparison),
      vendorLocation: ServiceItemPricingLocationModel.deserializeList(apiData.vendorLocation),
    };
    return new ServiceItemPricingModel(data);
  }

  public serialize(id) {
    return {
      id: id ? id : this.id || 0,
      serviceItemId: this.serviceItem.id,
      isCommissionable: this.isCommissionable,
      isDirectService: this.isDirectService,
      thirdPartyVendorComment: this.thirdPartyVendorComment,
      isVariablePricing: this.isVariablePricing,
      handlingFeeId: this.handlingFee.id,
      priceDataUnavailable: this.priceDataUnavailable,
      parameterId: this.parameter?.id,
      lowerLimit: this.lowerLimit || null,
      upperLimit: this.upperLimit || null,
      price: this.price || null,
      currencyId: this.currency?.id || null,
      unitId: this.uom?.id || null,
      uom: this.uom,
      userId: this.userId,
      comment: this.comment?.replace(/^\s*\s*$/, ''),
      validFrom: this.validFrom,
      validTo: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      statusId: this.status.id,
      vendorId: AuthStore.vendorProfile?.id,
      serviceItemPricingLocations: Array.from(new Set(this.vendorLocation.map(item => item.id))),
    };
  }

  public serializeApprovalData(data) {
    return {
      id: data.id ? data.id : this.id || 0,
      serviceItemId: data.serviceItem.id,
      isCommissionable: data.isCommissionable,
      isDirectService: data.isDirectService,
      thirdPartyVendorComment: data.thirdPartyVendorComment,
      isVariablePricing: data.isVariablePricing,
      handlingFeeId: data.handlingFee.id,
      priceDataUnavailable: data.priceDataUnavailable,
      parameterId: data.parameter?.id,
      lowerLimit: data.lowerLimit || null,
      upperLimit: data.upperLimit || null,
      price: data.price || null,
      currencyId: data.currency?.id || null,
      unitId: data.uom?.id || null,
      uom: data.uom,
      userId: data.userId,
      comment: data.comment?.replace(/^\s*\s*$/, ''),
      validFrom: data.validFrom,
      validTo: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      statusId: data.status.id,
      vendorId: AuthStore.vendorProfile?.id,
      serviceItemPricingLocations: Array.from(new Set(data.vendorLocation.map(item => item.id))),
    };
  }

  public serializeHoursPricing(
    id: number,
    serviceItemId: number,
    price: number,
    unitId: number,
    statusId?: number,
    currencyId?: number
  ) {
    return {
      id: id,
      serviceItemId: serviceItemId,
      isCommissionable: false,
      isDirectService: true,
      thirdPartyVendorComment: '',
      isVariablePricing: false,
      handlingFeeId: 1,
      priceDataUnavailable: false,
      lowerLimit: null,
      upperLimit: null,
      price: price,
      currencyId: currencyId ? currencyId : 1,
      unitId: parseInt(unitId) || null,
      userId: '',
      comment: '',
      validFrom: this.validFrom,
      validTo: this.validTo,
      statusId: statusId ? statusId : 1,
      vendorId: AuthStore.vendorProfile?.id,
      serviceItemPricingLocations: [ RootDataStore.locationData.locationId ],
    };
  }

  public serializeList(data) {
    return data.map(item => item.serialize());
  }

  static deserializeList(apiDataList: ServiceItemPricingModel[]): ServiceItemPricingModel[] {
    return apiDataList ? apiDataList.map((apiData: any) => ServiceItemPricingModel.deserialize(apiData)) : [];
  }
}
