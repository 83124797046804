import { modelProtection } from '@wings-shared/core';
import { BaseModel } from './Base.model';
import { AuthStore } from '@uplink-shared/security';

@modelProtection
export class WorldEventSpecialConsiderationModel extends BaseModel {
  userId: string = '';
  id: number = 0;
  worldEventReferenceId: number = 0;
  specialConsiderationId: number = 0;
  specialConsiderationName: string = '';
  worldEventSpecialConsiderationId: number = 0;

  constructor(data?: Partial<WorldEventSpecialConsiderationModel>) {
    super(data);
    Object.assign(this, data);
  }

  public serialize(): any {
    return {
      userId: AuthStore?.user?.preferred_username || '',
      id: 0,
      worldEventReferenceId: this.worldEventReferenceId  || 0,
      specialConsiderationId: this.worldEventSpecialConsiderationId || this.specialConsiderationId || 0,
      specialConsiderationName: this.name || this.specialConsiderationName || '',
    };
  }

  static deserialize(apiData: WorldEventSpecialConsiderationModel): WorldEventSpecialConsiderationModel {
    if (!apiData) {
      return new WorldEventSpecialConsiderationModel();
    }
    const data: Partial<WorldEventSpecialConsiderationModel> = {
      ...apiData,
      id: apiData.worldEventSpecialConsiderationId || apiData.specialConsiderationId || 0,
      specialConsiderationId: apiData.worldEventSpecialConsiderationId || apiData.specialConsiderationId || 0,
    };
    return new WorldEventSpecialConsiderationModel(data);
  }

  static deserializeList(apiDataList: WorldEventSpecialConsiderationModel[]): WorldEventSpecialConsiderationModel[] {
    return apiDataList
      ? apiDataList.map((apiData: any) => WorldEventSpecialConsiderationModel.deserialize(apiData))
      : [];
  }

  public get label(): string {
    return this.specialConsiderationName || this.name || '';
  }

  public get value(): string | number {
    return this.id || this.specialConsiderationId || 0;
  }
}
