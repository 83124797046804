import { VendorManagementStore } from './VendorManagement.store';
import { SettingsStore } from './Settings.store';
import { VendorLocationStore } from './VendorLocation.store';
import { VendorDocumentStore } from './VendorDocument.store';
import { ContactMasterStore } from './ContactMaster.store';
import { ServiceItemPricingStore } from './ServiceItemPricing.store';
import { ApprovalSettingsStore } from './ApprovalSettings.store';
import { ApprovalDataStore } from './ApprovalData.store';
import { VendorUserStore } from './VendorUser.store';
import { PassengerLogisticsStore } from './PassengerLogistics.store';
import { UserManagementStore } from './UserManagement.store';
import { SlideOneAndTwoStore } from './VendorLocationOnBoarding/SlideOneAndTwoStore.store';
import { SlideThreeStore } from './VendorLocationOnBoarding/SlideThreeStore.store';
import { VendorLocationHoursStore } from './VendorLocationHours.store';
import { BulletinStore } from './Bulletin.store';
import { DataExpiryStore } from './DataExpiry.store';
import { EventsStore } from './Events.store';
import { AirsideLogisticsStore } from './AirsideLogistics.store';
import { SlideSevenStore } from './VendorLocationOnBoarding/SlideSevenStore.store';
import { HealthRequirementStore } from './HealthRequirement.store';
import { SlideEightStore } from './VendorLocationOnBoarding/SlideEightStore.store';
import { CabotageStore } from './Cabotage.store';

class VendorRootStore {
  public vendorManagementStore: VendorManagementStore = new VendorManagementStore();
  public settingsStore: SettingsStore = new SettingsStore();
  public vendorLocationStore: VendorLocationStore = new VendorLocationStore();
  public vendorDocumentStore: VendorDocumentStore = new VendorDocumentStore();
  public contactMasterStore: ContactMasterStore = new ContactMasterStore();
  public serviceItemPricingStore: ServiceItemPricingStore = new ServiceItemPricingStore();
  public approvalSettingsStore: ApprovalSettingsStore = new ApprovalSettingsStore();
  public approvalDataStore: ApprovalDataStore = new ApprovalDataStore();
  public vendorUserStore: VendorUserStore = new VendorUserStore();
  public passengerLogisticsStore: PassengerLogisticsStore = new PassengerLogisticsStore();
  public userManagementStore: UserManagementStore = new UserManagementStore();
  public slideOneAndTwoStore: SlideOneAndTwoStore = new SlideOneAndTwoStore();
  public slideThreeStore: SlideThreeStore = new SlideThreeStore();
  public vendorLocationHoursStore: VendorLocationHoursStore = new VendorLocationHoursStore();
  public bulletinStore: BulletinStore = new BulletinStore();
  public dataExpiryStore: DataExpiryStore = new DataExpiryStore();
  public eventsStore: EventsStore = new EventsStore();
  public airsideLogisticsStore: AirsideLogisticsStore = new AirsideLogisticsStore();
  public slideSevenStore: SlideSevenStore = new SlideSevenStore();
  public healthRequirementStore: HealthRequirementStore = new HealthRequirementStore();
  public slideEightStore: SlideEightStore = new SlideEightStore();
  public cabotageStore: CabotageStore = new CabotageStore();
}

const rootStore = new VendorRootStore();

export default rootStore;
