import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { IAPISearchFiltersDictionary, IClasses } from '@wings-shared/core';
import { inject, observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { TabContext, TabPanel } from '@material-ui/lab';
import { Box, Button, Tab, Tabs, Typography, styled, withStyles } from '@material-ui/core';
import { ConfirmNavigate, DetailsEditorWrapper, SidebarStore } from '@uplink-shared/layout';
import { PassengerLogisticsStore } from '../../../../Stores';
import { styles } from './PassengerLogistics.styles';
import PassengerTransport from './Components/PassengerTransport/PassengerTransport';
import { CustomDialog, SidebarMenuOperationalInfo } from '../../../Shared';
import AircraftFacilities from './Components/AircraftFacilities/AircraftFacilities';
import Logistics from './Components/Logistics/Logistics';
import { ModeStore } from '@wings-shared/mode-store';
import AirsideLogistics from './Components/Logistics/AirsideLogistics/AirsideLogistics';
import { ModalStore } from '@uvgo-shared/modal-keeper';

interface Props {
  classes?: IClasses;
  searchFilters: IAPISearchFiltersDictionary;
  passengerLogisticsStore: PassengerLogisticsStore;
}

const CustomTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },
  width: '100%',
});


const PassengerLogistics: FC<Props> = ({ classes, passengerLogisticsStore }) => {
  const navigate = useNavigate();
  const [ isBlocker, setIsBlocker ] = useState(false);
  const [ activeTab, setActiveTab ] = useState('1');
  const [ activeSubTab, setActiveSubTab ] = useState('3.1');
  const [ isNextDisabled, setIsNextDisabled ] = useState(true);
  const saveDataFuncRef = useRef({});

  const handleIsBlockerUpdate = (value: boolean) => {
    setIsBlocker(value);
  };

  const CustomTab = styled(props => <Tab disableRipple {...props} />)(() => ({
    flex: 1,
    textTransform: 'none',
    padding: 0,
    color: '#7C7C7C',
    fontWeight: 600,
    fontSize: '14px',
    backgroundColor: '#D3D3D3',
    '&.Mui-selected': {
      color: '#202020',
      borderLeft: '4px solid #004BA0',
      background: '#FFFFFF',
    },
  }));

  const CustomSubTab = styled(props => <Tab disableRipple {...props} />)(() => ({
    textTransform: 'none',
    fontWeight: 600,
    '&.Mui-selected': {
      color: '#004BA0',
      borderBottom: '4px solid #004BA0',
      background: '#FFFFFF',
    },
  }));

  useEffect(() => {
    SidebarStore.setNavLinks(SidebarMenuOperationalInfo(), 'vendor', 'Operational Information', '/vendor/locations');
  }, []);

  const registerSaveData = (key, saveDataFunc) => {
    if (saveDataFunc) {
      saveDataFuncRef.current[key] = saveDataFunc;
    } else {
      delete saveDataFuncRef.current[key];
    }
  };

  const handleSaveClick = () => {
    const saveDataFunc = saveDataFuncRef.current[activeTab];
    const saveDataFuncSubTab = saveDataFuncRef.current[activeSubTab];
    if (saveDataFunc) {
      saveDataFunc();
    } else if(saveDataFuncSubTab){
      saveDataFuncSubTab();
    } else{
      console.warn('Save function not available for');
    }
  };

  const handleNextButtonDisableState = disabled => {
    setIsNextDisabled(disabled);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
    setIsNextDisabled(true);
    if (newValue === '3') {
      setActiveSubTab('3.1');
    } 
  };

  const handleSubTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveSubTab(newValue);
  };

  const TABS = [
    {
      label: 'Aircraft Facilities',
      value: '1',
      component: AircraftFacilities,
      desc: 'Aircraft Facilities',
      onNextButtonDisable: handleNextButtonDisableState,
      registerSaveData: func => registerSaveData('1', func),
    },
    ...(ModeStore.isDevModeEnabled
      ? [
        {
          label: 'Passenger Transport',
          value: '2',
          component: PassengerTransport,
          desc: 'Passenger Transport',
          onNextButtonDisable: handleNextButtonDisableState,
          registerSaveData: func => registerSaveData('2', func),
        },
      ]
      : []),
    {
      label: 'Logistics',
      value: '3',
      component: '',
      desc: 'Logistics',
      onNextButtonDisable: handleNextButtonDisableState,
      registerSaveData: func => registerSaveData('3', func),
      subTabs: [
        {
          label: 'Departure Logistics',
          value: '3.1',
          component: Logistics,
          onNextButtonDisable: handleNextButtonDisableState,
          registerSaveData: func => registerSaveData('3.1', func),
        },
        {
          label: 'Airside Logistics',
          value: '3.2',
          component: AirsideLogistics,
          onNextButtonDisable: handleNextButtonDisableState,
          registerSaveData: func => registerSaveData('3.2', func),
        },
      ],
    },
  ];

  const headerActions = (): ReactNode => {
    return (
      <>
        <Typography variant="h5">Passenger Logistics Facilities</Typography>
        <Box sx={{ display: 'flex' }}>
          <div className={`${classes.defaultButton}`}>
            <Button color="primary" variant="outlined" onClick={() => navigate('/vendor/locations')} size="large">
              Cancel
            </Button>
          </div>
          <div className={`${classes.primaryButton} ${classes.defaultButton}`}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              disabled={isNextDisabled}
              onClick={handleSaveClick}
            >
              Save
            </Button>
          </div>
        </Box>
      </>
    );
  };

  return (
    <div className={classes?.mainAirportHoursWrapper}>
      <ConfirmNavigate isBlocker={isBlocker}>
        <DetailsEditorWrapper
          headerActions={headerActions()}
          isEditMode={true}
          classes={{ headerActions: classes.headerActions }}
        >
          <div className={classes?.editorWrapperContainer}>
            <TabContext value={activeTab}>
              <CustomTabs value={activeTab} onChange={handleChange} variant="fullWidth">
                {TABS.map((tab, index) => (
                  <CustomTab key={tab.value} label={tab.label} value={tab.value} />
                ))}
              </CustomTabs>
              {TABS.map((tab, index) => (
                <TabPanel key={tab.value} value={tab.value}>
                  {tab.component && (
                    <Box>
                      <tab.component
                        updateIsBlocker={handleIsBlockerUpdate}
                        onNextButtonDisable={handleNextButtonDisableState}
                        registerSaveData={func => registerSaveData(activeTab, func)}
                      />
                    </Box>
                  )}
                  {tab.subTabs && activeTab === '3' && (
                    <TabContext value={activeSubTab}>
                      <CustomTabs value={activeSubTab} onChange={handleSubTabChange} 
                        variant="fullWidth">
                        {tab.subTabs.map((subTab) => (
                          <CustomSubTab key={subTab.value} label={subTab.label} value={subTab.value} />
                        ))}
                      </CustomTabs>
                      {tab.subTabs.map((subTab) => (
                        <TabPanel key={subTab.value} value={subTab.value}>
                          <subTab.component
                            updateIsBlocker={handleIsBlockerUpdate}
                            onNextButtonDisable={handleNextButtonDisableState}
                            registerSaveData={func => registerSaveData(subTab.value, func)}
                          />
                        </TabPanel>
                      ))}
                    </TabContext>
                  )}
                </TabPanel>
              ))}
            </TabContext>
          </div>
        </DetailsEditorWrapper>
      </ConfirmNavigate>
    </div>
  );
};

export default inject('passengerLogisticsStore')(withStyles(styles)(observer(PassengerLogistics)));
