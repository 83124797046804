import { createStyles, Theme } from '@material-ui/core';

export const styles = createStyles((theme: Theme) => ({
  editorWrapperContainer: {
    overflow: 'auto',
  },
  toggle: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    marginTop: '10px',
    '& p': {
      fontSize: '14px',
      fontWeight: 600,
    },
  },
  heading: {
    fontWeight: 600,
    fontSize: '14px',
  },
  imageIcon: {
    height: '16px',
    width: '16px',
    cursor: 'pointer',
    color: '#1976D2',
    pointerEvents: 'all',
    marginLeft: theme.spacing(1),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(11),
    color: theme.palette.text.secondary,
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
}));
