import { createStyles, Theme, lighten } from '@material-ui/core';

export const styles = createStyles((theme: Theme) => ({
  dialogContainerWidth: {
    '& .MuiDialog-paperWidthSm': {
      width: '420px',
    },
  },
  editorWrapperContainer: {
    '& .MuiFormLabel-root': {
      fontSize: '12px',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      cursor: 'pointer',
    },
    '& .MuiOutlinedInput-adornedEnd svg': {
      width: '16px !important',
      height: '16px !important',
    },
    '& .MuiChip-label': {
      fontSize: '14px',
      paddingTop: '2px',
    },
    '& .MuiChip-colorPrimary': {
      backgroundColor: '#004ba0',
    },
  },
  headerActions: {
    justifyContent: 'space-between',
    borderBottom: '1px solid #D3D3D3',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    '& button': {
      width: '150px',
      marginRight: '10px',
    },
    '& h5': {
      fontSize: '1.625rem',
    },
    '& .MuiTypography-h6': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      wordWrap: 'normal',
      whiteSpace: 'nowrap',
      fontSize: '1.625rem',
    },
  },
  customButton: {
    '& button': {
      border: '1px solid #1976D2',
      padding: '4px 10px',
      height: '40px',
      borderRadius: '4px',
      fontSize: '14px',
      fontWeight: '400',
      width: '100%',
      textTransform: 'capitalize',
      color: '#1976D2',
    },
  },
  defaultButton: {
    marginLeft: '16px',
    '& button': {
      padding: '4px 10px',
      height: '40px',
      borderRadius: '4px',
      fontSize: '14px',
      fontWeight: '400',
      width: '140px',
      minWidth: '140px',
      textTransform: 'capitalize',
      color: '#1976D2',
    },
  },
  primaryButton: {
    '& button:hover': {
      backgroundColor: 'rgba(99, 164, 255, 0.1)',
    },
    '& button:disabled': {
      background: '#afafaf',
      border: 'none',
    },
  },
  imageIcon: {
    height: '15px',
    width: '15px',
    cursor: 'pointer',
    color: '#1976D2',
    pointerEvents: 'all',
    marginLeft: theme.spacing(1),
  },
  mainTerminalOnlyWrapper: {
    '& .layout-contentWrapper [class*="MuiFormLabel-root"]': {
      fontSize: '14px',
    },
    '& > .MuiBox-root': {
      marginTop: '0',
      marginBottom: '15px',
    },
  },
  heading: {
    fontSize: '14px',
    fontWeight: 600,
    margin: '0 0 4px 0',
  },
  uploadedFileBox: {
    display: 'flex',
    gap: '10px',
    flexWrap: 'wrap',
  },
  fileBox: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '6px 10px 6px 0px',
    borderRadius: '4px',
    borderColor: `${lighten(theme.palette.divider, 0.6)}`,
    borderWidth: '2px',
    borderStyle: 'dashed',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    height: '42px',
    fontWeight: 400,
    boxSizing: 'border-box',
    flex: '1 1 calc(33.33% - 10px)',
    maxWidth: 'calc(33.33% - 10px)',
    [theme.breakpoints.down('md')]: {
      flex: '1 1 calc(50% - 10px)',
      maxWidth: 'calc(50% - 10px)',
    },
    [theme.breakpoints.down('sm')]: {
      flex: '1 1 calc(100% - 10px)',
      maxWidth: 'calc(100% - 10px)',
    },
  },
  innerFileBox: {
    display: 'flex',
    alignItems: 'center',
  },
  fileName: {
    fontSize: '14px !important',
    fontWeight: '400 !important',
    color: '#202020',
  },
  fileSizeBox: {
    display: 'flex',
    gap: '10px',
    color: '#202020',
    alignItems: 'center',
    '& .MuiIconButton-root': {
      color: '#7C7C7C',
    },
  },
}));
