import { createStyles, Theme } from '@material-ui/core';

export const styles = (theme: Theme) =>
  createStyles({
    customHeight: {
      height: 'calc(100vh - 250px)',
    },
    buttonContainer:{
      '& button:first-child':{
        marginRight: theme.spacing(2)
      },
    },
    root:{
      color: '#1976D2'
    },
    subHeader:{
      fontSize:'18px',
      fontWeight: 'bold'
    },
    radioHeader:{
      fontSize:'14px',
      paddingBottom:'20px',
      fontWeight: 'bold'
    },
    confirmBtn:{
      height:'40px',
      width:'144px',
      marginLeft:'10px'
    }

    
  });