import { auditFields } from '@uplink/shared';

export const airsideLogisticsFields = {
  ...auditFields,
  id: {
    label: 'Arrival/Departure Id',
  },
  walkingDistance: {
    label: 'How far do the passengers typically have to walk from the airplane on arrival?',
    rules: 'required'
  },
  shuttleAvailability: {
    label: 'Is a ramp side shuttle available and provided if needed?'
  },
  isShuttleAdditionalFee: {
    label: 'Is there an additional fee for the use of the ramp side shuttle?'
  },
  additionalFee: {
    label: 'What is the additional fee for the use of the rame side shuttle?'
  }
}
