import React, { FC, useEffect, useState } from 'react';
import { BaseStore, AirsideLogisticsStore, SettingsStore, VendorLocationStore } from '../../../../../../../Stores';
import { useStyles } from './Airside.styles';
import { IAPISearchFiltersDictionary, IClasses, IOptionValue, UIStore } from '@wings-shared/core';
import {
  AccordionRadioGroups,
  AirsideLogisticsModel,
  CustomAccordion,
  SETTING_ID,
  SettingBaseModel,
  SidebarMenuOperationalInfo,
  ViewInputControls,
} from '../../../../../../Shared';
import { RootDataStore, SidebarStore } from '@uplink-shared/layout';
import { inject, observer } from 'mobx-react';
import {
  Box,
  FormControl,
  FormHelperText,
  OutlinedInput,
  Typography,
  withStyles,
} from '@material-ui/core';
import { useUnsubscribe } from '@wings-shared/hooks';
import { finalize, takeUntil } from 'rxjs/operators';
import { useBaseUpsertComponent } from '@uplink/shared';
import { EDITOR_TYPES, IGroupInputControls } from '@uplink-shared/form-controls';
import { airsideLogisticsFields } from './Fields';
import { useParams } from 'react-router';

interface Props {
  classes?: IClasses;
  vendorLocationStore: VendorLocationStore;
  settingsStore: SettingsStore;
  airsideLogisticsStore: AirsideLogisticsStore;
  searchFilters: IAPISearchFiltersDictionary;
  onNextButtonDisable?: (boolean) => void;
  registerSaveData: (saveData: () => void) => void;
  updateIsBlocker: (value: boolean) => void;
}

const AirsideLogistics: FC<Props> = ({
  registerSaveData,
  vendorLocationStore,
  settingsStore,
  airsideLogisticsStore,
  onNextButtonDisable,
  updateIsBlocker,
}) => {
  const classes = useStyles();
  const [ departureErrorMessage, setDepartureErrorMessage ] = useState('');
  const [ isFormChanged, setIsFormChanged ] = useState(false);
  const params = useParams();
  const useUpsert = useBaseUpsertComponent<AirsideLogisticsModel>(params, airsideLogisticsFields);

  useEffect(() => {
    onNextButtonDisable(true);
  }, []);

  useEffect(() => {
    if (isFormChanged) {
      validateFormFields();
      
    }
    updateIsBlocker(isFormChanged);
  }, [ isFormChanged ]);

  const validateFormFields = () => {
    const walkingDistanceValue = useUpsert.getField('walkingDistance').value;
    
    if (
      walkingDistanceValue === null ||
      walkingDistanceValue === ''
    ) {
      onNextButtonDisable(true);
    } else {
      onNextButtonDisable(false);
    }
  };
  
  const saveData = () => {
    upsertAirsideLogistics();
  };

  const unsubscribe = useUnsubscribe();

  useEffect(() => {
    airsideLogisticsStore.logisticsData = [];
    airsideLogisticsStore.otherNameField = '';
    airsideLogisticsStore.isValueChanged = false;
    updateIsBlocker(false);
    SidebarStore.setNavLinks(SidebarMenuOperationalInfo(), 'vendor', 'Operational Information', '/vendor/locations');
    settingsStore.getSettings(SETTING_ID.SETTING_AIRCRAFT_PARKING_DISTANCE_FBO).subscribe();
    loadInitialData();
  }, []);

  useEffect(() => {
    registerSaveData(saveData);
    return () => registerSaveData(null);
  }, []);

  useEffect(() => {
    updateIsBlocker(airsideLogisticsStore.isValueChanged);
    setRequiredRules();
  }, [ 
    useUpsert.form.changed
  ]);

  useEffect(() => {
    airsideLogisticsStore.isRampsideShuttleAvailableData === 1 && 
    airsideLogisticsStore.isAdditionalFeeRampsideData === 3 ? 
      validateAdditionalFee(airsideLogisticsStore.additionalFee) : null
  }, [ 
    airsideLogisticsStore.isRampsideShuttleAvailableData, 
    airsideLogisticsStore.isAdditionalFeeRampsideData 
  ]);


  const setRequiredRules = () => {
    useUpsert.getField('walkingDistance').set('rules', 'required');
    useUpsert.getField('additionalFee').set({ rules: [ 'required','string','between:1,100' ] });
  };

  const setFieldValues = data => {
    airsideLogisticsStore.isValueChanged = false;
    useUpsert.setFormValues(data);
    useUpsert.getField('walkingDistance').set(data.walkingDistance);
    useUpsert.getField('shuttleAvailability').set(data.shuttleAvailability);
    useUpsert.getField('isShuttleAdditionalFee').set(data.isShuttleAdditionalFee);
    useUpsert.getField('additionalFee').set(data.additionalFee);
  };

  const groupInputControls = (): IGroupInputControls[] => {
    return [
      {
        title: '',
        inputControls: [
          {
            fieldKey: 'id',
            type: EDITOR_TYPES.TEXT_FIELD,
            isHidden: true
          },
          {
            fieldKey: 'walkingDistance',
            type: EDITOR_TYPES.DROPDOWN,
            options: AirsideLogisticsModel?.walkingDistanceOptions(),
            isFullFlex: true,
            getOptionLabel: (option: IOptionValue) => (option as SettingBaseModel)?.name,
          },
        ],
      },
    ];
  };

  const onValueChange = (value: IOptionValue, fieldKey: string): void => {
    airsideLogisticsStore.isValueChanged = true;
    useUpsert.getField(fieldKey).set(value);
    setIsFormChanged(true);
    validateFormFields()
  };

  const loadInitialData = () => {
    UIStore.setPageLoader(true);
    vendorLocationStore
      ?.getVendorLocationById(RootDataStore.locationData.locationId)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: AirsideLogisticsModel) => {
        if (response.airsideLogisticHuman) {
          setFieldValues(AirsideLogisticsModel.deserialize(response.airsideLogisticHuman));
          airsideLogisticsStore.operationalInsightsData = AirsideLogisticsModel.deserialize(
            response?.airsideLogisticHuman
          );
          airsideLogisticsStore.isRampsideShuttleAvailableData =
            airsideLogisticsStore.operationalInsightsData.shuttleAvailability === 'Yes' ? 1 : 2;
          airsideLogisticsStore.isAdditionalFeeRampsideData =
            airsideLogisticsStore.operationalInsightsData.isShuttleAdditionalFee === 'Yes' ? 3 : 4;
          airsideLogisticsStore.additionalFee = response.airsideLogisticHuman.additionalFee;
          airsideLogisticsStore.isValueChanged = false;
          onNextButtonDisable(true);
          updateIsBlocker(false);
        }
      });
  };

  const isRampsideShuttleAvailable = [
    { id: 1, value: 'Yes', label: 'Yes' },
    { id: 2, value: 'No', label: 'No' },
  ];
  const isAdditionalFeeRampside = [
    { id: 3, value: 'Yes', label: 'Yes' },
    { id: 4, value: 'No', label: 'No' },
  ];

  const errorHandler = (errors: object, id): void => {
    Object.values(errors)?.forEach(errorMessage => BaseStore.showAlert(errorMessage[0], id));
  };

  const upsertAirsideLogistics = (): void => {
    const formValues = useUpsert.form.values();

    const request = new AirsideLogisticsModel({
      ...formValues,
      shuttleAvailability: airsideLogisticsStore.isRampsideShuttleAvailableData,
      isShuttleAdditionalFee: 
        airsideLogisticsStore.isRampsideShuttleAvailableData === 2 ? 
          null : airsideLogisticsStore.isAdditionalFeeRampsideData,
      additionalFee:  
        airsideLogisticsStore.isAdditionalFeeRampsideData === 4 || 
        airsideLogisticsStore.isRampsideShuttleAvailableData === 2 ? 
          null : airsideLogisticsStore.additionalFee,
      walkingDistance: formValues.walkingDistance,
    });
    UIStore.setPageLoader(true);
    airsideLogisticsStore
      ?.upsertVendorLocationAirsideLogistics(request.serialize())
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: AirsideLogisticsModel) => {
          setFieldValues(response);
          airsideLogisticsStore.isValueChanged = false;
          loadInitialData();
          updateIsBlocker(false);
        },
        error: error => {
          errorHandler(error.response?.data?.errors, request.id.toString());
        },
      });
  };

  const onSearch = (searchValue: string, fieldKey: string): void => {
    switch (fieldKey) {
      default:
        break;
    }
    return;
  };

  const onFocus = (fieldKey: string): void => {
    switch (fieldKey) {
      default:
        break;
    }
  };

  const handleChange = (event) => {
    const walkingDistanceValue = useUpsert.getField('walkingDistance').value;
    airsideLogisticsStore.isValueChanged = true;

    if (event === 3) {
      setRequiredRules();
      onNextButtonDisable(true);
    } else {
      onNextButtonDisable(false);
    }

    if(walkingDistanceValue === null || walkingDistanceValue ===''){
      onNextButtonDisable(true);
    }

  };
  
  const validateAdditionalFee = (value: string | null) => {
    if (value === null || value === undefined) {
      setDepartureErrorMessage('This field is required.');
      onNextButtonDisable(true);
    } else if (value.length > 100 || value.length < 1) {
      setDepartureErrorMessage('This field must be between 1 and 100 characters.');
      onNextButtonDisable(true);
    } else {
      setDepartureErrorMessage('');
      onNextButtonDisable(false);
      if(useUpsert.getField('walkingDistance').value === null){
        onNextButtonDisable(true);
      }
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    airsideLogisticsStore.isRampsideShuttleAvailableData === 1 && 
    airsideLogisticsStore.isAdditionalFeeRampsideData === 3 ? 
      (
        airsideLogisticsStore.additionalFee = e.target.value, validateAdditionalFee(e.target.value)
      )
      : null;
  };

  return (
    <Box>
      <CustomAccordion
        panelName="gatOperationalHours"
        panelHeading="Airside Logistics"
        panelBodyHeading=""
        panelContent={
          <div>
            <div className={classes.arrivalSelectDropdown}>
              <ViewInputControls
                isEditable={true}
                groupInputControls={groupInputControls()}
                onGetField={(fieldKey: string) => useUpsert.getField(fieldKey)}
                onValueChange={(option, fieldKey) => onValueChange(option, fieldKey)}
                field={fieldKey => useUpsert.getField(fieldKey)}
                onSearch={(searchValue: string, fieldKey: string) => onSearch(searchValue, fieldKey)}
                onFocus={fieldKey => onFocus(fieldKey)}
              />
            </div>
            <AccordionRadioGroups
              radioValue={airsideLogisticsStore.isRampsideShuttleAvailableData}
              onRadioChange={value => {
                airsideLogisticsStore.isRampsideShuttleAvailableData = value;
                airsideLogisticsStore.isValueChanged = true;
                handleChange(value);
              }}
              radioGroupData={isRampsideShuttleAvailable}
              label="Is a ramp side shuttle available and provided if needed?"
            />
            {airsideLogisticsStore.isRampsideShuttleAvailableData === 1 && (
              <Box>
                <AccordionRadioGroups
                  radioValue={airsideLogisticsStore.isAdditionalFeeRampsideData}
                  onRadioChange={value => {
                    airsideLogisticsStore.isAdditionalFeeRampsideData = value;
                    airsideLogisticsStore.isValueChanged = true;
                    onNextButtonDisable(false);
                    handleChange(value);
                  }}
                  radioGroupData={isAdditionalFeeRampside}
                  label="Is there an additional fee for the use of the ramp side shuttle?"
                />
              </Box>
            )}
            {airsideLogisticsStore.isRampsideShuttleAvailableData === 1 &&
              airsideLogisticsStore.isAdditionalFeeRampsideData === 3 && (
              <Box>
                <FormControl variant="outlined">
                  <Typography className="radioLabel">
                    {'What is the additional fee for the use of the ramp side shuttle?'}
                  </Typography>
                  <OutlinedInput
                    className="inputTextField"
                    value={airsideLogisticsStore.additionalFee}
                    onChange={handleInputChange}
                    labelWidth={0}
                    style={{
                      borderColor: departureErrorMessage ? 'red' : 'initial',
                      borderWidth: '1px',
                      borderStyle: departureErrorMessage ? 'solid' : 'none',
                    }}
                    placeholder="Enter here"
                  />
                  {departureErrorMessage && (
                    <FormHelperText style={{ color: 'red' }}>{departureErrorMessage}</FormHelperText>
                  )}
                </FormControl>
              </Box>
            )}
          </div>
        }
      />
    </Box>
  );
};

export default inject(
  'settingsStore',
  'vendorLocationStore',
  'airsideLogisticsStore'
)(observer(AirsideLogistics));
