import { CoreModel, ISelectOption, modelProtection } from '@wings-shared/core';
import { VendorLocationModel } from './VendorLocation.model';
import { AppliedDistinctModel } from './AppliedDisinsection.model';
import { RootDataStore } from '@uplink-shared/layout';

@modelProtection
export class HealthRequirementModel extends CoreModel implements ISelectOption {
  userId: string = '';
  id: number = 0;
  healthRequirementId: number = 0;
  vendorLocation: VendorLocationModel = new VendorLocationModel();
  isDisinsection: boolean = false;
  isDisinsectionCountryAll: boolean = false;
  isDocRequired: boolean = false;
  appliedDisinsectionCountries: AppliedDistinctModel[] = [];
  appliedDisinsectionTypes: AppliedDistinctModel[] = [];
  appliedDisinsectionChemicals: AppliedDistinctModel[] = [];
  // appliedDisinsectionDocuments:  SettingBaseModel = new SettingBaseModel();

  constructor(data?: Partial<HealthRequirementModel>) {
    super(data);
    Object.assign(this, data);
  }

  static deserialize(apiData: HealthRequirementModel): HealthRequirementModel {
    if (!apiData) {
      return new HealthRequirementModel();
    }

    const data: Partial<HealthRequirementModel> = {
      ...apiData,
      id: apiData.healthRequirementId || apiData.id || 0,
      vendorLocation: VendorLocationModel.deserialize({
        ...apiData.vendorLocation,
        id: apiData.vendorLocation?.vendorLocationId,
      }),
      appliedDisinsectionCountries: AppliedDistinctModel.deserializeList(apiData.appliedDisinsectionCountries),
      appliedDisinsectionTypes: AppliedDistinctModel.deserializeList(apiData.appliedDisinsectionTypes),
      appliedDisinsectionChemicals: AppliedDistinctModel.deserializeList(apiData.appliedDisinsectionChemicals),
    };
    return new HealthRequirementModel(data);
  }

  static deserializeList(apiDataList: HealthRequirementModel[]): HealthRequirementModel[] {
    return apiDataList ? apiDataList.map((apiData: any) => HealthRequirementModel.deserialize(apiData)) : [];
  }

  public serialize() {
    return {
      id: this.id || this.healthRequirementId || 0,
      userId: this.userId,
      vendorLocationId: RootDataStore.locationData.locationId,
      isDisinsection: this.isDisinsection,
      isDisinsectionCountryAll: this.isDisinsectionCountryAll,
      isDocRequired: this.isDocRequired,
      appliedDisinsectionCountryRequest: this.appliedDisinsectionCountries?.map(data => ({
        userId: this.userId || '',
        id: data.appliedDisinsectionCountryId || 0,
        countryId: data?.countryReference?.id || 0,
        countryName: data?.countryReference?.commonName || '',
        countryCode: data?.countryReference?.code || '',
      })),
      appliedDisinsectionTypeRequest: this.appliedDisinsectionTypes?.map(data => ({
        userId: this.userId || '',
        id: data.appliedDisinsectionTypeId || 0,
        disinsectionTypeId: data?.subDisinsectionType?.id || 0,
        disinsectionType: data?.subDisinsectionType?.name || '',
      })),
      appliedDisinsectionChemicalRequest: this.appliedDisinsectionChemicals?.map(data => ({
        userId: this.userId || '',
        id: data.appliedDisinsectionChemicalId || 0,
        disinsectionChemicalId: data?.subDisinsectionChemical?.id || 0,
        disinsectionChemical: data?.subDisinsectionChemical?.name || '',
      })),
      appliedDisinsectionDocumentRequest: []
    };
  }

  public get label(): string {
    return this.name;
  }

  public get value(): string | number {
    return this.id;
  }
}
