import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { SlideOneAndTwoStore, VendorLocationStore } from '../../Stores';
import { inject, observer } from 'mobx-react';
import { IClasses, UIStore } from '@wings-shared/core';
import { Box, Step, Stepper, Typography, withStyles, StepIconProps } from '@material-ui/core';
import { styles } from './VendorLocationOnBoarding.styles';
import { DetailsEditorWrapper, SidebarStore } from '@uplink-shared/layout';
import { newVendorOnboarding, CustomTooltip, VendorOnBoardSlideOneNTwoModel } from '../Shared';
import Slide1 from './Components/Slide1/Slide1';
import Slide2 from './Components/Slide2/Slide2';
import Slide3 from './Components/Slide3/Slide3';
import { PrimaryButton } from '@uvgo-shared/buttons';
import Check from '@material-ui/icons/Check';
import { AuthStore } from '@uplink-shared/security';
import { finalize, takeUntil } from 'rxjs/operators';
import { useUnsubscribe } from '@wings-shared/hooks';
import { useNavigate } from 'react-router';
import Slide4 from './Components/Slide4/Slide4';
import Slide7 from './Components/Slide7/Slide7';
import Slide8 from './Components/Slide8/Slide8';

interface Props {
  classes?: IClasses;
  vendorLocationStore: VendorLocationStore;
  slideOneAndTwoStore: SlideOneAndTwoStore;
}

const VendorLocationOnBoarding: FC<Props> = ({ classes, vendorLocationStore, slideOneAndTwoStore }) => {
  const [ activeStep, setActiveStep ] = React.useState(slideOneAndTwoStore.activeStep);
  const [ onBoardingSlides, setOnBoardingSlides ] = React.useState([]);
  const [ isNextDisabled, setIsNextDisabled ] = useState(false);
  const slideRefs = useRef({});
  const unsubscribe = useUnsubscribe();
  const navigate = useNavigate();

  const CustomStepIcon = (props: StepIconProps & { slide: number; onBoardingSlides: number[] }) => {
    const { active, slide, onBoardingSlides } = props;

    const isSlideCompleted = onBoardingSlides.includes(slide);

    const getBackgroundColor = () => {
      if (isSlideCompleted) {
        return 'rgba(101, 166, 27, 1)';
      } else if (active) {
        return '#1976D2';
      } else {
        return 'rgba(95, 95, 95, 0.15)';
      }
    };

    const getColor = () => {
      if (isSlideCompleted || active) {
        return 'white';
      } else {
        return 'rgba(95, 95, 95, 0.15)';
      }
    };

    return (
      <div
        style={{
          backgroundColor: getBackgroundColor(),
          color: getColor(),
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 30,
          height: 30,
        }}
      >
        {isSlideCompleted ? <Check style={{ color: 'white' }} /> : slide}
      </div>
    );
  };

  const handleNextButtonDisableState = disabled => {
    setIsNextDisabled(disabled);
  };

  const steps = [
    {
      slide: 1,
      component: (
        <Slide1
          registerSaveData={func => registerSaveData('slide1', func)}
          activeStep={slideOneAndTwoStore.activeStep}
          setActiveStep={setActiveStep}
          onNextButtonDisable={handleNextButtonDisableState}
        />
      ),
    },
    {
      slide: 2,
      component: (
        <Slide2
          registerSaveData={func => registerSaveData('slide2', func)}
          activeStep={slideOneAndTwoStore.activeStep}
          setActiveStep={setActiveStep}
          onNextButtonDisable={handleNextButtonDisableState}
        />
      ),
    },
    {
      slide: 3,
      component: (
        <Slide3
          registerSaveData={func => registerSaveData('slide3', func)}
          activeStep={slideOneAndTwoStore.activeStep}
          setActiveStep={setActiveStep}
          onNextButtonDisable={handleNextButtonDisableState}
        />
      ),
    },
    {
      slide: 4,
      component: (
        <Slide4
          registerSaveData={func => registerSaveData('slide4', func)}
          activeStep={slideOneAndTwoStore.activeStep}
          setActiveStep={setActiveStep}
          onNextButtonDisable={handleNextButtonDisableState}
        />
      ),
    },
    {
      slide: 5,
      component: (
        <Slide4
          registerSaveData={func => registerSaveData('slide5', func)}
          activeStep={slideOneAndTwoStore.activeStep}
          setActiveStep={setActiveStep}
          onNextButtonDisable={handleNextButtonDisableState}
        />
      ),
    },
    {
      slide: 6,
      component: (
        <Slide4
          registerSaveData={func => registerSaveData('slide6', func)}
          activeStep={slideOneAndTwoStore.activeStep}
          setActiveStep={setActiveStep}
          onNextButtonDisable={handleNextButtonDisableState}
        />
      ),
    },
    {
      slide: 7,
      component: (
        <Slide7
          registerSaveData={func => registerSaveData('slide7', func)}
          activeStep={slideOneAndTwoStore.activeStep}
          setActiveStep={setActiveStep}
          onNextButtonDisable={handleNextButtonDisableState}
        />
      ),
    },
    {
      slide: 8,
      component: (
        <Slide8
          registerSaveData={func => registerSaveData('slide8', func)}
          activeStep={slideOneAndTwoStore.activeStep}
          setActiveStep={setActiveStep}
          onNextButtonDisable={handleNextButtonDisableState}
        />
      ),
    }
  ];

  useEffect(() => {
    SidebarStore.setNavLinks(newVendorOnboarding(), 'vendor', 'Vendor Locations', '/vendor/locations');
    vendorLocationStore.operationTypeId = 0;
    loadInitialData();
  }, [ AuthStore.vendorProfile?.id ]);

  useEffect(() => {
    if (slideOneAndTwoStore.tempLocationId) {
      vendorOnBoardingTracking();
    }
  }, [ slideOneAndTwoStore.tempLocationId ]);

  useEffect(() => {
    if (slideOneAndTwoStore.tempLocationId) {
      slideSkipTracking();
    }
  }, [ slideOneAndTwoStore.activeStep ]);

  const headerActions = (): ReactNode => (
    <Typography variant="h5">
      <CustomTooltip title="New Location Onboarding Questions" />
    </Typography>
  );

  const vendorOnBoardingTracking = () => {
    UIStore.setPageLoader(true);
    slideOneAndTwoStore
      .getByVendorOnboardTracking(slideOneAndTwoStore.tempLocationId)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe(response => {
        const slideNos = response?.map(item => {
          const match = item.slideNo.match(/\d+/);
          return match ? parseInt(match[0], 10) : null;
        }).filter(slide => slide !== null);
        slideNos.sort((a, b) => a - b);
        setOnBoardingSlides(slideNos);
        const lastSlideNo = slideNos.length > 0 ? slideNos[slideNos.length - 1] : 0;
        slideOneAndTwoStore.activeStep = lastSlideNo - 1;
        setActiveStep(lastSlideNo - 1);
      });
  };

  const slideSkipTracking = () => {
    UIStore.setPageLoader(true);
    slideOneAndTwoStore
      .getByVendorOnboardTracking(slideOneAndTwoStore.tempLocationId)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe(response => {
        const slideNos = response?.map(item => {
          const match = item.slideNo.match(/\d+/);
          return match ? parseInt(match[0], 10) : null;
        });
        setOnBoardingSlides(slideNos);
      });
  };

  const handleNext = () => {
    const currentSlideKey = `slide${slideOneAndTwoStore.activeStep + 1}`;
    const saveDataFunc = slideRefs.current[currentSlideKey];
    if (saveDataFunc) {
      saveDataFunc();
    } else {
      console.warn('Save function not available for', currentSlideKey);
    }
  };

  const handlePrevious = () => {
    slideOneAndTwoStore.activeStep = activeStep - 1;
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleSkip = () => {
    slideOneAndTwoStore.activeStep = activeStep + 1;
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const registerSaveData = (slideKey, saveDataFunc) => {
    if (saveDataFunc) {
      slideRefs.current[slideKey] = saveDataFunc;
    } else {
      delete slideRefs.current[slideKey];
    }
  };

  const handleSaveResumeLater = () => {
    const currentSlideKey = `slide${activeStep + 1}`;
    const saveDataFunc = slideRefs.current[currentSlideKey];

    if (saveDataFunc) {
      saveDataFunc();
    } else {
      console.warn('Save function not available for', currentSlideKey);
    }
  };

  const loadInitialData = () => {
    UIStore.setPageLoader(true);
    if (AuthStore.vendorProfile?.id) {
      slideOneAndTwoStore
        .getByVendorId(AuthStore.vendorProfile?.id)
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => {})
        )
        .subscribe((response: VendorOnBoardSlideOneNTwoModel) => {
          if (response[0]) {
            slideOneAndTwoStore.tempLocationId = response[0].tempLocationId;
          }
        });
    }
  };

  const handleCancel = (): void => {
    navigate('/vendor/locations');
  };

  return (
    <DetailsEditorWrapper
      headerActions={headerActions()}
      isEditMode={true}
      classes={{ headerActions: classes.headerActions }}
    >
      <Box className={classes.outerBox}>
        <Box className={classes.innerBox1}>
          <Stepper nonLinear activeStep={slideOneAndTwoStore.activeStep}>
            {steps.map((item, index) => (
              <Step key={item.slide}>
                <CustomStepIcon
                  active={slideOneAndTwoStore.activeStep === index}
                  icon={item.slide}
                  slide={item.slide}
                  onBoardingSlides={onBoardingSlides}
                />
              </Step>
            ))}
          </Stepper>
        </Box>

        <Box className={classes.innerBox2}>{steps[slideOneAndTwoStore.activeStep]?.component}</Box>

        <Box className={classes.thirdBox}>
          <Box>
            {activeStep === 0 && (
              <Box className={classes.innerBox3}>
                <Box className={`${classes.defaultButton}`}>
                  <PrimaryButton variant="outlined" onClick={handleCancel}>
                    Cancel
                  </PrimaryButton>
                </Box>
              </Box>
            )}
            {activeStep !== 0 && (
              <Box className={classes.innerBox3}>
                <Box className={`${classes.defaultButton}`}>
                  <PrimaryButton variant="outlined" onClick={handlePrevious}>
                    Previous
                  </PrimaryButton>
                </Box>
                <Box className={`${classes.defaultButton}`}>
                  <PrimaryButton
                    disabled={steps.length - 1 === activeStep || activeStep === 1}
                    variant="outlined"
                    onClick={handleSaveResumeLater}
                    style={{ marginLeft: 8 }}
                  >
                    Save & Resume Later
                  </PrimaryButton>
                </Box>
              </Box>
            )}
          </Box>
          <Box className={classes.innerBox3}>
            {activeStep !== 0 && activeStep !== 1 && (
              <PrimaryButton
                disabled={activeStep === 0 || activeStep === 1 || steps.length - 1 === activeStep}
                variant="text"
                onClick={handleSkip}
                style={{ marginRight: 8 }}
              >
                Skip
              </PrimaryButton>
            )}
            <Box className={`${classes.primaryButton} ${classes.defaultButton}`}>
              <PrimaryButton
                variant="contained"
                color="primary"
                onClick={handleNext}
                disabled={UIStore.pageLoading || isNextDisabled}
              >
                Next
              </PrimaryButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </DetailsEditorWrapper>
  );
};

export default inject(
  'vendorLocationStore',
  'slideOneAndTwoStore'
)(withStyles(styles)(observer(VendorLocationOnBoarding)));
