import { createStyles, Theme } from '@material-ui/core';

export const styles = createStyles((theme: Theme) => ({
  defaultButton: {
    marginLeft: '16px',
    '& button': {
      padding: '4px 10px',
      height: '40px',
      borderRadius: '4px',
      fontSize: '14px',
      fontWeight: '400',
      width: '140px',
      minWidth: '140px',
      textTransform: 'capitalize',
      color: '#1976D2',
    },
  },
  primaryButton: {
    '& button': {
      background: '#1976D2',
      color: '#fff',
    },
    '& button:hover': {
      backgroundColor: '#63A4FF',
    },
    '& button:disabled': {
      background: '#afafaf',
      border: 'none',
    },
  },
}));
