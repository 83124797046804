import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { BaseStore, BulletinStore, VendorLocationStore, EventsStore } from '../../../../Stores';
import { IAPIGridRequest, IAPIPageResponse, IClasses, SearchStore, UIStore, MODEL_STATUS } from '@wings-shared/core';
import { inject, observer } from 'mobx-react';
import { CircularProgress, withStyles } from '@material-ui/core';
import {
  CustomHeader,
  SidebarMenuOperationalInfo,
  VENDOR_LOCATION_DATA_FILTER,
  CustomButton,
  InfiniteScroll,
  CustomList,
  IAPIVMSVendorLocationComparison,
  VendorLocationModel,
  CustomDialog,
  COLLECTION_NAMES,
} from '../../../Shared';
import { ISearchHeaderRef, SearchHeaderV2 } from '@uplink-shared/form-controls';
import { RootDataStore, SidebarStore } from '@uplink-shared/layout';
import { useNavigate, useParams } from 'react-router';
import { useAgGrid, useGridState } from '@uplink-shared/custom-ag-grid';
import { useUnsubscribe } from '@wings-shared/hooks';
import { AnalyticsStore } from '@uplink-shared/analytics';
import { MixPanelTrackingEvents } from '@uplink/shared';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { finalize, takeUntil } from 'rxjs/operators';
import { styles } from './Events.styles';
import { gridFilters } from '../../../VendorLocations/Fields';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { WorldEvents } from '../../../Shared/Models/WorldEvents.model';

interface Props {
  vendorLocationStore: VendorLocationStore;
  bulletinStore: BulletinStore;
  eventsStore: EventsStore;
  classes: IClasses;
}

const Events: FC<Props> = ({ vendorLocationStore, classes, bulletinStore, eventsStore }) => {
  const searchHeaderRef = useRef<ISearchHeaderRef>();
  const navigate = useNavigate();
  const gridState = useGridState();
  const agGrid = useAgGrid<VENDOR_LOCATION_DATA_FILTER, WorldEvents>(gridFilters, gridState);
  const unsubscribe = useUnsubscribe();
  const [ airportDataManagement, setAirportDataManagement ] = useState(false);
  const params = useParams();
  const isCountry = params.type === 'country';
  const [ isSpinnerVisible, setIsSpinnerVisible ] = useState(true);

  useEffect(() => {
    loadVendorLocationData();
    loadLocationAddress();
    AnalyticsStore.track(MixPanelTrackingEvents.VENDOR_LOCATION_BULLETINS);
    SidebarStore.setNavLinks(SidebarMenuOperationalInfo(), 'vendor', 'Operational Information', '/vendor/locations');
    vendorLocationStore.hasDataLoaded = true;
  }, [ airportDataManagement ]);

  const loadLocationAddress = () => {
    UIStore.setPageLoader(true);
    const cityAddressRequest: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      filterCollection: JSON.stringify([
        {
          propertyName: 'VendorLocation.VendorLocationId',
          propertyValue: RootDataStore.locationData.locationId,
        },
      ]),
    };
    eventsStore
      .getVMSComparison(COLLECTION_NAMES.VENDOR_LOCATION_ADDRESS, cityAddressRequest)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe(response => {
        const results = response.results;
        if (results.length === 0) {
          addAddressesNotification();
          setIsSpinnerVisible(false);
        } else {
          loadInitialData({ pageNumber: 1 });
        }
      });
  };

  useEffect(() => {
    if (!RootDataStore.locationData.countryDataManagement && isCountry) {
      navigate('/vendor/locations');
    }
  }, [ RootDataStore.locationData.countryDataManagement ]);

  const loadVendorLocationData = () => {
    UIStore.setPageLoader(true);
    vendorLocationStore
      ?.getVendorLocationById(RootDataStore.locationData.locationId)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: VendorLocationModel) => {
        if (response) {
          setAirportDataManagement(response.airportDataManagement);
        }
      });
  };
  const rightContent = (): ReactNode => {
    return (
      <div className={classes.buttonContainer}>
        <CustomButton
          variant="contained"
          startIcon={<AddRoundedIcon />}
          to={`/vendor/location/${params.type}/events/new`}
          title="Add Event"
        />
      </div>
    );
  };

  const searchCollection = (): IAPIGridRequest | null => {
    const propertyValue = getSearchValue();
    if (!propertyValue) {
      vendorLocationStore.hasDataLoaded = true;
      return null;
    }
    const filters = [
      {
        propertyName: 'Name',
        propertyValue: propertyValue,
        filterType: 'string',
      },
      {
        propertyName: 'Description',
        propertyValue: propertyValue,
        filterType: 'string',
        operator: 'or',
      },
      {
        propertyName: 'Type',
        propertyValue: propertyValue,
        filterType: 'string',
        operator: 'or',
      },
      {
        propertyName: 'Category',
        propertyValue: propertyValue,
        filterType: 'string',
        operator: 'or',
      },
    ];
    return {
      searchCollection: JSON.stringify(filters),
    };
  };

  const getSearchValue = (): string => {
    const searchHeader = searchHeaderRef.current?.getFilters();
    if (!searchHeader) {
      return null;
    }
    return searchHeader.searchValue || '';
  };

  const setSearchData = (): void => {
    const searchHeaderFilter = searchHeaderRef.current.getFilters();
    SearchStore.searchData.set(location.pathname, {
      searchValue: searchHeaderFilter?.searchValue,
      selectInputsValues: searchHeaderFilter?.selectInputsValues,
      chipValue: searchHeaderFilter.chipValue,
      pagination: gridState.pagination,
    });
  };

  const loadInitialData = (pageRequest?: IAPIGridRequest) => {
    if (eventsStore.locationAddress.length > 0) {
      UIStore.setPageLoader(true);
      const request: IAPIGridRequest = {
        pageNumber: gridState.pagination.pageNumber,
        pageSize: gridState.pagination.pageSize,

        filterCollection: JSON.stringify([
          {
            propertyName: 'Status.Name',
            propertyValue: 'Active',
          },
          ...(RootDataStore.locationData.airportReferenceId
            ? [
              {
                propertyName: 'AppliedWorldEventAirportReference.AirportId',
                propertyValue: [ RootDataStore.locationData.airportReferenceId ],
                filterType: 'in',
                operator: 'and',
              },
            ]
            : []),
          {
            propertyName: 'AppliedWorldEventCountryReference.CountryId',
            propertyValue: [ eventsStore.locationAddress[0]?.countryReference?.countryId ],
            filterType: 'in',
            operator: 'and',
          },
          {
            propertyName: 'AppliedWorldEventCityReference.CityId',
            propertyValue: [ eventsStore.locationAddress[0]?.cityReference?.id ],
            filterType: 'in',
            operator: 'and',
          },
        ]),
        ...searchCollection(),
        ...agGrid.filtersApi.gridSortFilters(),
        ...pageRequest,
      };
      eventsStore
        .getWorldEvents(request)
        .pipe(
          takeUntil(unsubscribe.destroy$),
          finalize(() => {
            UIStore.setPageLoader(false);
          })
        )
        .subscribe((response: IAPIPageResponse<IAPIVMSVendorLocationComparison>) => {
          const data = response.results;
          if (data.length === 0) {
            vendorLocationStore.hasDataLoaded = false;
            return;
          }
          if (response.pageNumber === 1) {
            gridState.setGridData([]);
          }
          gridState.setGridData([ ...gridState.data, ...data ]);
        });
    }
  };

  const colDefNew = [
    {
      headerName: 'Name',
      field: 'name',
    },
    {
      headerName: 'Description',
      field: 'description',
    },
    {
      headerName: 'Type',
      field: 'eventTypeName',
    },
    {
      headerName: 'Category',
      field: 'eventCategoryName',
    },
    {
      headerName: 'Start Date',
      field: 'startDate',
    },
    {
      headerName: 'End Date',
      field: 'endDate',
    },
    {
      field: 'actionRenderer',
      headerName: '\u00A0\u00A0\u00A0\u00A0Edit\u00A0\u00A0\u00A0\u00A0',
    },
  ];

  const errorHandler = (errors: object, id): void => {
    Object.values(errors)?.forEach(errorMessage => BaseStore.showAlert(errorMessage[0], id));
  };

  const upsertWorldEvent = (item): void => {
    UIStore.setPageLoader(true);
    const request = new WorldEvents({ ...item });
    eventsStore
      ?.upsertVendorLocationHanlderEvents(request.serialize(2))
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: WorldEvents) => {
          gridState.setGridData([]);
          loadInitialData({ pageNumber: 1 });
        },
        error: error => {
          errorHandler(error.response?.data?.errors, request.id.toString());
        },
      });
  };

  const onEdit = (item, e) => {
    e.preventDefault();
    e.stopPropagation();
    setSearchData();
    navigate(`/vendor/location/${params.type}/events/edit/${item?.id}`);
  };

  const getDeleteConfirmation = (item): void => {
    ModalStore.open(
      <CustomDialog
        title="Remove this Event"
        message={'Are you sure you want to remove this event?'}
        yesButton="Confirm"
        noButton="Cancel"
        onNoClick={() => {
          ModalStore.close();
        }}
        onYesClick={() => upsertWorldEvent(item)}
      />
    );
  };

  const onDelete = (item, e) => {
    getDeleteConfirmation(item);
  };

  const addAddressesNotification = (): void => {
    ModalStore.open(
      <CustomDialog
        title="Add Address"
        message={'There is no <strong>Physical Address</strong> for this location, please add'}
        yesButton="Click Here"
        noButton="Cancel"
        onNoClick={() => {
          ModalStore.close();
          navigate('/vendor/locations');
        }}
        onYesClick={() => {
          ModalStore.close();
          navigate('/vendor/location/addresses');
        }}
      />
    );
  };

  const onDetails = (item, e) => {
    e.preventDefault();
    e.stopPropagation();
    setSearchData();
    navigate(`/vendor/location/${params.type}/events/details/${item?.id}`);
  };

  return (
    <>
      <CustomHeader title="Events" />
      <SearchHeaderV2
        placeHolder="Search"
        ref={searchHeaderRef}
        selectInputs={[]}
        isUsingSelectInputs={false}
        onResetFilterClick={() => {
          gridState.setGridData([]);
          loadInitialData({ pageNumber: 1 });
        }}
        rightContent={rightContent}
        onFilterChange={isInitEvent => {
          gridState.setGridData([]);
          vendorLocationStore.hasDataLoaded = true;
          loadInitialData({ pageNumber: isInitEvent ? gridState.pagination.pageNumber : 1 });
        }}
        isLoading={UIStore.pageLoading}
      />
      <InfiniteScroll
        pageStart={0}
        loadMore={page => {
          const searchData = SearchStore.searchData.get(location.pathname);
          if (searchData) {
            searchHeaderRef.current?.setupDefaultFilters(searchData);
            SearchStore.clearSearchData(location.pathname);
          }
          setTimeout(() => {
            loadInitialData({ pageNumber: page });
          }, 300);
        }}
        resetPagination={eventsStore.locationAddress.length != 0}
        hasMore={vendorLocationStore.hasDataLoaded && !UIStore.pageLoading}
        loader={<div style={{ textAlign: 'center' }}>{isSpinnerVisible && <CircularProgress />}</div>}
        useWindow={false}
      >
        <CustomList
          classes={classes}
          colDef={colDefNew}
          rowData={gridState.data}
          isHeaderVisible={false}
          onEdit={(item, e) => onEdit(item, e)}
          onDetails={(item, e) => onDetails(item, e)}
          onDelete={(item, e) => onDelete(item, e)}
          selectedItemId={RootDataStore.locationData.locationId}
          isLoading={UIStore.pageLoading}
          // isAirportBulletins={true}
          isHandlerEvents={true}
          showDetailButton={false}
          showEditButton={true}
          showDeleteButton={true}
        />
      </InfiniteScroll>
    </>
  );
};

export default inject('vendorLocationStore', 'bulletinStore', 'eventsStore')(withStyles(styles)(observer(Events)));
