import React, { FC } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import { observer } from 'mobx-react';
import { useStyles } from './CustomDialog.style';
import { PrimaryButton } from '@uvgo-shared/buttons';

type Props = {
  title: string;
  message: string;
  yesButton: string;
  noButton: string;
  onNoClick: () => void;
  onYesClick: () => void;
  className?: string;
};

const CustomDialog: FC<Props> = ({
  title,
  message,
  yesButton,
  noButton,
  onNoClick,
  onYesClick,
  className,
}) => {
  const classes = useStyles();

  const [ open, setOpen ] = React.useState(true);

  const handleClose = () => {
    onYesClick();
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} disableBackdropClick={true}>
      <div className={`${classes.dialog} ${className}`}>
        <WarningRoundedIcon className={classes.warningIcon} style={{ textAlign: 'center', display: 'inline-block' }} />

        <DialogTitle className={classes.title}>
          <strong>{title}</strong>
        </DialogTitle>
        <DialogContent className={classes.content} dangerouslySetInnerHTML={{ __html: message }} />
        <DialogActions>
          <Box className={classes.defaultButton}>
            <PrimaryButton onClick={onNoClick} variant="text">
              {noButton}
            </PrimaryButton>
          </Box>
          <Box className={`${classes.primaryButton} ${classes.defaultButton}`}>
            <PrimaryButton onClick={handleClose} autoFocus variant="contained">
              {yesButton}
            </PrimaryButton>
          </Box>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default observer(CustomDialog);
