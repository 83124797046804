import { CoreModel, ISelectOption, modelProtection } from '@wings-shared/core';
import { VendorLocationModel } from './VendorLocation.model';
import { SettingBaseModel } from './SettingBase.model';
import { CabotageSettingOptionModel } from './CabotageSettingOption.model';
import { RootDataStore } from '@uplink-shared/layout';

@modelProtection
export class CabotageModel extends CoreModel implements ISelectOption {
  userId: string = '';
  id: number = 0;
  cabotageOperationalRequirementId: number = 0;
  vendorLocation: VendorLocationModel = new VendorLocationModel();
  isCabotageEnforced: boolean = false;
  cabotageExemptionLevel: SettingBaseModel = new SettingBaseModel();
  isImportationFeesforDomesticFlight: boolean = false;
  isCustomsStopsExempt: boolean = false;
  isPaxMustDepartwithSameOperator: boolean = false;
  isNoNewPaxAllowedtoDepart: boolean = false;
  isCabotageAppliestoLivestock: boolean = false;
  isCabotageAppliestoCargo: boolean = false;
  isCabotageAppliestoNonResidents: boolean = false;
  appliedRegionCabotageExemptions: CabotageSettingOptionModel[] = [];
  appliedCountryCabotageExemptions: CabotageSettingOptionModel[] = [];
  cabotageEnforcedForFARTypes: CabotageSettingOptionModel[] = [];

  constructor(data?: Partial<CabotageModel>) {
    super(data);
    Object.assign(this, data);
  }

  static deserialize(apiData: CabotageModel): CabotageModel {
    if (!apiData) {
      return new CabotageModel();
    }

    const data: Partial<CabotageModel> = {
      ...apiData,
      id: apiData.cabotageOperationalRequirementId || apiData.id || 0,
      vendorLocation: VendorLocationModel.deserialize({
        ...apiData.vendorLocation,
        id: apiData.vendorLocation?.vendorLocationId,
      }),
      appliedRegionCabotageExemptions: CabotageSettingOptionModel.deserializeList(
        apiData.appliedRegionCabotageExemptions
      ),
      appliedCountryCabotageExemptions: CabotageSettingOptionModel.deserializeList(
        apiData.appliedCountryCabotageExemptions
      ),
      cabotageEnforcedForFARTypes: CabotageSettingOptionModel.deserializeList(apiData.cabotageEnforcedForFARTypes),
      cabotageExemptionLevel: SettingBaseModel.deserialize(apiData.cabotageExemptionLevel),
    };
    return new CabotageModel(data);
  }

  public static getCabotageApplyList(): ISelectOption[] {
    return SettingBaseModel.deserializeList([
      { id: 1, name: 'Country' },
      { id: 2, name: 'Region' },
    ]);
  }

  static deserializeList(apiDataList: CabotageModel[]): CabotageModel[] {
    return apiDataList ? apiDataList.map((apiData: any) => CabotageModel.deserialize(apiData)) : [];
  }

  public static getYesNo() {
    return SettingBaseModel.deserializeList([
      {
        id: 1,
        name: 'Yes',
      },
      {
        id: 2,
        name: 'No',
      },
    ]);
  }

  public serialize() {
    return {
      id: this.id || this.cabotageOperationalRequirementId || 0,
      userId: this.userId,
      vendorLocationId: RootDataStore.locationData.locationId,
      isCabotageEnforced: this.isCabotageEnforced,
      cabotageExemptionLevelId: this.cabotageExemptionLevel?.id,
      cabotageExemptionLevelName: this.cabotageExemptionLevel?.name,
      isImportationFeesforDomesticFlight: this.isImportationFeesforDomesticFlight === 1 ? true : false,
      isCustomsStopsExempt: this.isCustomsStopsExempt === 1 ? true : false,
      isPaxMustDepartwithSameOperator: this.isPaxMustDepartwithSameOperator === 1 ? true : false,
      isNoNewPaxAllowedtoDepart: this.isNoNewPaxAllowedtoDepart === 1 ? true : false,
      isCabotageAppliestoLivestock: this.isCabotageAppliestoLivestock === 1 ? true : false,
      isCabotageAppliestoCargo: this.isCabotageAppliestoCargo === 1 ? true : false,
      isCabotageAppliestoNonResidents: this.isCabotageAppliestoNonResidents === 1 ? true : false,
      appliedRegionCabotageExemptions: this.appliedRegionCabotageExemptions.map(data => ({
        userId: this.userId || '',
        id: data.appliedRegionCabotageExemptionId || data.id || 0,
        regionId: data?.region?.id || 0,
        regionCode: data?.region?.code || '',
        regionName: data?.region?.name || '',
      })),
      appliedCountryCabotageExemptions: this.appliedCountryCabotageExemptions.map(data => ({
        userId: this.userId || '',
        id: data.appliedCountryCabotageExemptionId || data.id || 0,
        countryId: data?.country?.id || 0,
        countryName: data?.country?.commonName || '',
        countryCode: data?.country?.code || '',
      })),
      cabotageEnforcedForFARTypes: this.cabotageEnforcedForFARTypes.map(data => ({
        userId: this.userId || '',
        id: data.cabotageEnforcedForFARTypeId || data.id || 0,
        permitFARTypeId: data?.farType?.id || 0,
        name: data?.farType?.name || '',
        cappsCode: data?.farType?.code || '',
      })),
    };
  }

  public get label(): string {
    return this.name;
  }

  public get value(): string | number {
    return this.id;
  }
}
