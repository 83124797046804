import { regex } from '@wings-shared/core';
import { auditFields } from '@uplink/shared';
import { VALIDATION_REGEX } from '../../../Shared/Enums/Spacing.enum';

export const fields = {
  ...auditFields,
  id: {
    label: 'User Id',
  },
  email: {
    label: 'Email Address*',
    rules: `required|regex:${regex.email}`,
  },
  givenName: {
    label: 'First Name*',
    rules: 'required|string|between:0,200',
  },
  surName: {
    label: 'Last Name*',
    rules: 'required|string|between:0,200',
  },
  userRole: {
    label: 'User Role*',
    rules: 'required',
  },
  vendorUserLocation: {
    label: 'Location*',
    rules: 'required',
    value: [],
  },
  userId: {
    label: 'UserId',
  },
  userName: {
    label: 'UserName',
  },
  phoneNo: {
    label: 'Phone Number*',
    rules:
      'required|between:7,20|regex:/^\\+?[0-9]{1,3}([-.\\s]?\\d{1,4}){1,5}$/',
  }
};
