import {
  GRID_ACTIONS,
  GridPagination,
  IAPIGridRequest,
  IAPIPageResponse,
  IClasses,
  SearchStore,
  UIStore,
  Utilities,
  cellStyle,
} from '@wings-shared/core';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { inject, observer } from 'mobx-react';
import { Box, CircularProgress, withStyles } from '@material-ui/core';
import { finalize, takeUntil } from 'rxjs/operators';
import { useAgGrid, useGridState } from '@uplink-shared/custom-ag-grid';
import { RootDataStore, SidebarStore } from '@uplink-shared/layout';
import { BaseStore, ContactMasterStore, VendorLocationStore } from '../../../../Stores';
import { useUnsubscribe } from '@wings-shared/hooks';
import { styles } from './LocationContacts.styles';
import { ISearchHeaderRef, SearchHeaderV2 } from '@uplink-shared/form-controls';
import { locationGridFilters } from './Fields';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import {
  VendorLocationModel,
  InfiniteScroll,
  CustomList,
  CustomTooltip,
  CustomDialog,
  SidebarMenus,
  CustomButton,
  CustomHeader,
  VendorLocationContactModel,
  VENDOR_LOCATION_COMPARISON_FILTERS,
} from '../../../Shared';
import { MixPanelTrackingEvents } from '@uplink/shared';
import { AnalyticsStore } from '@uplink-shared/analytics';
import { VendorGeneralInfoCard } from '../../../DataExpirationWorkflow';

interface Props {
  classes: IClasses;
  contactMasterStore?: ContactMasterStore;
  vendorLocationStore?: VendorLocationStore;
}

const LocationContacts: FC<Props> = ({ classes, vendorLocationStore, contactMasterStore }) => {
  const navigate = useNavigate();
  const gridState = useGridState();
  const location = useLocation();
  const isExpired = location.pathname.includes('/expiry/contacts');
  const searchHeaderRef = useRef<ISearchHeaderRef>();
  const agGrid = useAgGrid<VENDOR_LOCATION_COMPARISON_FILTERS, VendorLocationContactModel>(
    locationGridFilters,
    gridState
  );
  const [ selectedVendorLocation, setSelectedVendorLocation ] = useState(new VendorLocationModel());
  const unsubscribe = useUnsubscribe();

  useEffect(() => {
    AnalyticsStore.track(MixPanelTrackingEvents.VENDOR_LOCATION_CONTACT,
      { VendorLocationId:RootDataStore?.locationData?.locationId });
    SidebarStore.setNavLinks(SidebarMenus(), 'vendor');
    contactMasterStore.hasDataLoaded = true;
    loadVendorLocationData();
  }, []);

  const loadVendorLocationData = () => {
    UIStore.setPageLoader(true);
    vendorLocationStore
      ?.getVendorLocationById(RootDataStore.locationData.locationId)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe((response: VendorManagmentModel) => {
        setSelectedVendorLocation(response);
      });
  };

  const getConfirmation = (item: VendorLocationContactModel): void => {
    ModalStore.open(
      <CustomDialog
        title="Remove this Contact "
        message={'Are you sure you want to remove this Contact?'}
        yesButton="Remove this Contact"
        noButton="Cancel"
        onNoClick={() => ModalStore.close()}
        onYesClick={() => {
          contactMasterStore.hasDataLoaded = true;
          upsertLocationContact(item);
        }}
      />
    );
  };

  const upsertLocationContact = (model: VendorLocationContactModel): void => {
    UIStore.setPageLoader(true);
    contactMasterStore
      .upsertVendorLocationContact(
        model.serialize(
          model.id,
          [ model.vendorLocation.id ],
          model.contactUsegeType.id,
          model.contact.id,
          3,
          model.accessLevel.id,
          model.vendorId
        )
      )
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe({
        next: (response: VendorLocationContactModel) => {
          gridState.setGridData([]);
          loadLocationContactGridData({ pageNumber: 1 });
        },
        error: error => {
          BaseStore.showAlert(error.message, model.id.toString());
        },
      });
  };

  const searchCollection = (): IAPIGridRequest | null => {
    const propertyValue = getSearchValue();
    if (propertyValue === '') {
      return null;
    }
    const filters = [
      {
        propertyName: 'Contact.Contact',
        propertyValue: propertyValue,
      },
      {
        propertyName: 'ContactUsegeType.Name',
        operator: 'or',
        propertyValue: propertyValue,
      },
      {
        propertyName: 'Contact.ContactName',
        operator: 'or',
        propertyValue: propertyValue,
      },
      {
        propertyName: 'Contact.ContactMethod.Name',
        operator: 'or',
        propertyValue: propertyValue,
      },
      {
        propertyName: 'Contact.ContactType.Name',
        operator: 'or',
        propertyValue: propertyValue,
      },
      {
        propertyName: 'Contact.Title',
        operator: 'or',
        propertyValue: propertyValue,
      },
    ];
    return {
      searchCollection: JSON.stringify(filters),
    };
  };

  const loadLocationContactGridData = (pageRequest?: IAPIGridRequest) => {
    const request: IAPIGridRequest = {
      pageNumber: gridState.pagination.pageNumber,
      pageSize: gridState.pagination.pageSize,
      ...searchCollection(),
      ...agGrid.filtersApi.gridSortFilters(),
      ...pageRequest,
      filterCollection: JSON.stringify([
        {
          propertyName: 'VendorLocation.VendorLocationId',
          propertyValue: RootDataStore.locationData.locationId,
        },
        {
          propertyName: 'Status.Id',
          propertyValue: 3,
          filterType: 'ne',
        },
      ]),
    };
    UIStore.setPageLoader(true);
    vendorLocationStore
      ?.getVMSLocationContact(request)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        finalize(() => {
          UIStore.setPageLoader(false);
        })
      )
      .subscribe((response: IAPIPageResponse<VendorLocationContactModel>) => {
        const results = VendorLocationContactModel.deserializeList(response.results);
        if (results.length === 0) {
          contactMasterStore.hasDataLoaded = false;
          return;
        }
        if (response.pageNumber === 1) {
          gridState.setGridData([]);
        }
        gridState.setGridData([ ...gridState.data, ...results ]);
      });
  };

  const getSearchValue = (): string => {
    const searchHeader = searchHeaderRef.current?.getFilters();
    if (!searchHeader) {
      return null;
    }
    return searchHeader.searchValue || '';
  };

  const setSearchData = (): void => {
    const searchHeaderFilter = searchHeaderRef.current.getFilters();
    SearchStore.searchData.set(location.pathname, {
      searchValue: searchHeaderFilter?.searchValue,
      selectInputsValues: searchHeaderFilter?.selectInputsValues,
      chipValue: searchHeaderFilter.chipValue,
      pagination: gridState.pagination,
    });
  };

  const rightContent = (): ReactNode => {
    return (
      <div className={classes.buttonContainer}>
        <CustomButton
          variant="contained"
          startIcon={<AddRoundedIcon />}
          to={isExpired ? '/vendor/location/expiry/contacts/upsert':'/vendor/location/contact/upsert'}
          title="Add Contact"
        />
      </div>
    );
  };

  const colDefNew = [
    {
      headerName: 'Contact Method',
      field: 'contact.contactMethod',
    },
    {
      headerName: 'Contact Usage Type',
      field: 'contactUsegeType',
    },
    {
      headerName: 'Contact Type',
      field: 'contact.contactType',
    },
    {
      headerName: 'Contact Method',
      field: 'contact.contactMethod',
    },
    {
      headerName: 'Contact',
      field: 'contact.contact',
    },
    {
      headerName: 'Contact Method',
      field: 'contact.contactName',
    },
    {
      headerName: 'Title',
      field: 'contact.title',
    },
    {
      field: 'actionRenderer',
      headerName: '\u00A0\u00A0\u00A0\u00A0Edit\u00A0\u00A0\u00A0\u00A0\u00A0Delete',
    },
  ];

  const onEdit = item => {
    setSearchData();
    navigate(`upsert/${item?.id}/${item?.contact?.id}`);
  };

  return (
    <Box>
      {!window.location.href.includes('expiry') && <VendorGeneralInfoCard />}
      <CustomHeader
        title={
          <CustomTooltip title={`${selectedVendorLocation.name} Contacts - ${selectedVendorLocation.headerLabel()}`} />
        }
      />
      <SearchHeaderV2
        placeHolder="Start typing to search"
        ref={searchHeaderRef}
        selectInputs={[]}
        onResetFilterClick={() => {
          gridState.setGridData([]);
          contactMasterStore.hasDataLoaded = true;
          loadLocationContactGridData({ pageNumber: 1 });
        }}
        rightContent={rightContent}
        onFilterChange={isInitEvent => {
          gridState.setGridData([]);
          contactMasterStore.hasDataLoaded = true;
          loadLocationContactGridData({ pageNumber: isInitEvent ? gridState.pagination.pageNumber : 1 });
        }}
        isLoading={UIStore.pageLoading}
      />
      <InfiniteScroll
        pageStart={0}
        loadMore={page => {
          const searchData = SearchStore.searchData.get(location.pathname);
          if (searchData) {
            searchHeaderRef.current?.setupDefaultFilters(searchData);
            SearchStore.clearSearchData(location.pathname);
          }
          setTimeout(() => {
            loadLocationContactGridData({ pageNumber: page });
          }, 200);
        }}
        hasMore={contactMasterStore.hasDataLoaded && !UIStore.pageLoading}
        loader={
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
        }
        useWindow={false}
        resetPagination={!contactMasterStore.hasDataLoaded}
      >
        <CustomList
          classes={classes}
          colDef={colDefNew}
          rowData={gridState.data}
          isHeaderVisible={false}
          showEditButton={true}
          onEdit={item => onEdit(item)}
          onDelete={item => getConfirmation(item)}
          isContact={true}
          showDeleteButton={true}
          isLoading={UIStore.pageLoading}
        />
      </InfiniteScroll>
    </Box>
  );
};

export default inject('vendorLocationStore', 'contactMasterStore')(withStyles(styles)(observer(LocationContacts)));
