import { HttpClient, NO_SQL_COLLECTIONS, baseApiPath } from '@uplink/shared';
import { observable, action } from 'mobx';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AlertStore } from '@uvgo-shared/alert';
import { Logger } from '@wings-shared/security';
import { IAPIGridRequest, IAPIPageResponse, tapWithAction, Utilities } from '@wings-shared/core';
import { refDataHeaders, vendorManagementHeaders } from './Base.store';
import { IAPIVMSComparison } from '../Modules/Shared/Interfaces';
import { apiUrls } from './API.url';
import { AppliedDistinctModel, COLLECTION_NAMES, HealthRequirementModel, SettingBaseModel } from '../Modules/Shared';

export class HealthRequirementStore {
  @observable public formValueChanged: boolean = false;
  
  @action
  public getHealthRequirementData(
    collectionName: COLLECTION_NAMES,
    pageRequest?: IAPIGridRequest
  ): Observable<IAPIPageResponse<HealthRequirementModel>> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementNoSqlUrl,
      headers: vendorManagementHeaders,
    });
    const params: string = Utilities.buildParamString({
      CollectionName: collectionName,
      ...pageRequest,
    });
    return http.get<IAPIPageResponse<IAPIVMSComparison>>(`/${apiUrls.vendorManagement}?${params}`).pipe(
      Logger.observableCatchError,
      map(response => {
        const results = HealthRequirementModel.deserializeList(response.results);
        return { ...response, results: results };
      })
    );
  }

  @action
  public getHealthRequirementSettingOptions<T>(models: T[], settingName: string) {
    const settingOptions: AppliedDistinctModel[] = [];
    models.forEach(model => {
      const settingOption: AppliedDistinctModel = new AppliedDistinctModel();
      settingOption.id = 0;
      settingOption[settingName] = model;
      settingOptions.push(settingOption);
    });
    return settingOptions;
  }

  @action
  public upsertHealthRequirement(payload: HealthRequirementModel): Observable<HealthRequirementModel> {
    const http = new HttpClient({ headers: vendorManagementHeaders });
    payload.id = payload.id === null ? 0 : payload.id;
    const isNewRequest: boolean = !Boolean(payload.id);
    const upsertRequest: Observable<HealthRequirementModel> = isNewRequest
      ? http.post<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.healthRequirement}`, payload)
      : http.put<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.healthRequirement}/${payload.id}`, payload);
    return upsertRequest.pipe(
      Logger.observableCatchError,
      tap(() => {
        AlertStore.info('Data saved successfully!');
      }),
      map(response => HealthRequirementModel.deserialize(response))
    );
  }
}
