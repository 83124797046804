import { regex } from '@wings-shared/core';
import { auditFields } from '@uplink/shared';

export const fields = {
  ...auditFields,
  id: {
    label: 'Health Requirement Id',
  },
  vendorLocation: {
    label: 'Vendor Location',
  },
  appliedDisinsectionCountries:{
    label: 'Select Disinsection departure country*',
    rules: 'required'
  },
  appliedDisinsectionTypes:{
    label: 'Select Disinsection required type*',
    rules: 'required'
  },
  appliedDisinsectionChemicals:{
    label: 'Select Disinsection chemicals*',
    rules: 'required'
  },
};

export const nonRequiredfields = {
  ...auditFields,
  id: {
    label: 'Health Requirement Id',
  },
  vendorLocation: {
    label: 'Vendor Location',
  },
  appliedDisinsectionCountries:{
    label: 'Select Disinsection departure country',
  },
  appliedDisinsectionTypes:{
    label: 'Select Disinsection required type',
  },
  appliedDisinsectionChemicals:{
    label: 'Select Disinsection chemicals',
  },
};