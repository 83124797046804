import { HttpClient, baseApiPath } from '@uplink/shared';
import { observable, action } from 'mobx';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AlertStore } from '@uvgo-shared/alert';
import { Logger } from '@wings-shared/security';
import { IAPIPageResponse } from '@wings-shared/core';
import { vendorManagementHeaders } from './Base.store';
import { IAPIVMSComparison } from '../Modules/Shared/Interfaces';
import { apiUrls } from './API.url';
import { PassengerLogisticsModel } from '../Modules/Shared/Models/PassengerLogisticsModel.model';
import { PassengerLogisticsOptionModel } from '../Modules/Shared/Models/PassengerLogisticsOptionModel.model';
import { PassengerTransportModel } from '../Modules/Shared/Models/PassengerTransport.model';
import { DropOffAddressModel } from '../Modules/Shared/Models/DropOffAddress.model';
import { SettingBaseModel } from '../Modules/Shared';

export class PassengerLogisticsStore {
  @observable public mapValuechanged: boolean = false;
  @observable public towBarFieldRequired: boolean = false;
  @observable public isArrivalAirsidePickupData: number = 2;
  @observable public isArrivalSameLandisidePickupData: number = 3;
  @observable public isDepartureAirsidePickupData: number = 22;
  @observable public isDepartureSameLandisidePickupData: number = 33;

  @observable public isDepartureSameAsArrival: number = 5;

  @observable public arrivalAdditionalInfo: string = '';
  @observable public departureAdditionalInfo: string = '';
  @observable public arrivalData: DropOffAddressModel = new DropOffAddressModel();
  @observable public departureData: DropOffAddressModel = new DropOffAddressModel();

  @observable public disableSaveButton: boolean = true;
  @observable public logisticsData: SettingBaseModel[] = [];

  @observable public operationalInsightsData: PassengerLogisticsModel = new PassengerLogisticsModel();
  @observable public otherNameField: string = '';
  @observable public additionalInfo: string = '';

  @observable public isValueChanged: boolean = false;
  
  @action
  public getLocationOperationalInsightsById(id?: number): Observable<IAPIVMSComparison> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementNoSqlUrl,
      headers: vendorManagementHeaders,
    });
    return http.get<IAPIPageResponse<IAPIVMSComparison>>(`/${apiUrls.operationalInsight}/${id}`).pipe(
      Logger.observableCatchError,
      map(response => PassengerLogisticsModel.deserialize(response))
    );
  }

  @action
  public getOperationalInsightsSettingOptions<T>(models: T[], settingName: string) {
    const settingOptions: PassengerLogisticsOptionModel[] = [];
    models.forEach(model => {
      const settingOption: PassengerLogisticsOptionModel = new PassengerLogisticsOptionModel();
      settingOption.id = 0;
      settingOption.operationalInsightId = 0;
      settingOption[settingName] = model;
      settingOptions.push(settingOption);
    });
    return settingOptions;
  }

  @action
  public upsertVendorLocationOperationalInsights(
    payload: PassengerLogisticsModel,
    isAircraft: boolean
  ): Observable<PassengerLogisticsModel> {
    const http = new HttpClient({ headers: vendorManagementHeaders });
    payload.id = payload.id === null ? 0 : payload.id;
    const isNewRequest: boolean = !(payload.id != null && payload.id !== 0);
    const upsertRequest: Observable<PassengerLogisticsModel> = isNewRequest
      ? http.post<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.operationalInsight}`, payload)
      : http.put<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.operationalInsight}/${payload.id}`, payload);
    return upsertRequest.pipe(
      Logger.observableCatchError,
      tap(() =>
        AlertStore.info(
          isAircraft ? 'Aircraft Logistics data saved successfully!' : 'Passenger Logistics data saved successfully!'
        )
      ),
      map(response => PassengerLogisticsModel.deserialize(response))
    );
  }

  @action
  public upsertPassengerTransport(payload: PassengerTransportModel[]): Observable<PassengerTransportModel[]> {
    const http = new HttpClient({ headers: vendorManagementHeaders });
    payload[0].id = payload[0].id === null ? 0 : payload[0].id;
    const isNewRequest: boolean = !(payload[0].id != null && payload[0].id !== 0);
    const upsertRequest: Observable<PassengerTransportModel> = isNewRequest
      ? http.post<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorLocationPassengerTransport}`, payload)
      : http.put<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorLocationPassengerTransport}`, payload);
    return upsertRequest.pipe(
      Logger.observableCatchError,
      tap(() => AlertStore.info('Passenger Transport data saved successfully!')),
      map(response => PassengerTransportModel.deserializeList(response))
    );
  }
}
