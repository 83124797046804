import { regex } from '@wings-shared/core';
import { auditFields } from '@uplink/shared';

export const fields = {
  ...auditFields,
  id: {
    label: 'Vendor Location Id',
  },
  aircraftParkingField:{
    label: 'Aircraft Parking on Field',
    rules: 'string|between:0,300'
  },
  appliedAircraftParkingOptions:{
    label: 'Aircraft Parking Options'
  },
  aircraftParkingDistanceFBO:{
    label: 'Aircraft Parking Distance from FBO'
  },
  appliedInternationalArrivalProcedures:{
    label: 'Aircraft International Arrival Procedures',
  },
  appliedAircraftSpotAccommodation:{
    label: 'Aircraft Spot Accommodation'
  },
  hangarAvailable:{
    label: 'Hangar Available'
  },
  hangarAvailableSpace:{
    label: 'Hangar Available Space',
    rules: `numeric|between:0,999999|regex:${regex.numberOnly}`
  },
  hangerAvailableUom:{
    label: 'Hanger Available UOM'
  },
  towbarRequired:{
    label: 'Towbar Required'
  },
  appliedTowbarScenarios:{
    label: 'Towbar Required For'
  },
  towbarRequirement:{
    label: 'Towbar Requirements',
    rules: 'string|between:0,200'
  },
};
