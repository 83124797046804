import { HttpClient, baseApiPath } from '@uplink/shared';
import { observable, action } from 'mobx';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AlertStore } from '@uvgo-shared/alert';
import { Logger } from '@wings-shared/security';
import { IAPIGridRequest, IAPIPageResponse, Utilities } from '@wings-shared/core';
import { vendorManagementHeaders } from './Base.store';
import { IAPIVMSComparison } from '../Modules/Shared/Interfaces';
import { apiUrls } from './API.url';
import { CabotageModel, COLLECTION_NAMES, CabotageSettingOptionModel } from '../Modules/Shared';

export class CabotageStore {
  @observable public formValueChanged: boolean = false;

  @action
  public getCabotageData(
    collectionName: COLLECTION_NAMES,
    pageRequest?: IAPIGridRequest
  ): Observable<IAPIPageResponse<CabotageModel>> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementNoSqlUrl,
      headers: vendorManagementHeaders,
    });
    const params: string = Utilities.buildParamString({
      CollectionName: collectionName,
      ...pageRequest,
    });
    return http.get<IAPIPageResponse<IAPIVMSComparison>>(`/${apiUrls.vendorManagement}?${params}`).pipe(
      Logger.observableCatchError,
      map(response => {
        const results = CabotageModel.deserializeList(response.results);
        return { ...response, results: results };
      })
    );
  }

  @action
  public getCabotageSettingOptions<T>(models: T[], settingName: string) {
    const settingOptions: CabotageSettingOptionModel[] = [];
    models.forEach(model => {
      const settingOption: CabotageSettingOptionModel = new CabotageSettingOptionModel();
      settingOption.id = 0;
      settingOption[settingName] = model;
      settingOptions.push(settingOption);
    });
    return settingOptions;
  }

  @action
  public upsertCabotage(payload: CabotageModel): Observable<CabotageModel> {
    const http = new HttpClient({ headers: vendorManagementHeaders });
    payload.id = payload.id === null ? 0 : payload.id;
    const isNewRequest: boolean = !Boolean(payload.id);
    const upsertRequest: Observable<CabotageModel> = isNewRequest
      ? http.post<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.cabotage}`, payload)
      : http.put<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.cabotage}/${payload.id}`, payload);
    return upsertRequest.pipe(
      Logger.observableCatchError,
      tap(() => {
        AlertStore.info('Data saved successfully!');
      }),
      map(response => CabotageModel.deserialize(response))
    );
  }
}
