import { HttpClient, baseApiPath } from '@uplink/shared';
import { observable, action } from 'mobx';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AlertStore } from '@uvgo-shared/alert';
import { Logger } from '@wings-shared/security';
import { IAPIPageResponse } from '@wings-shared/core';
import { vendorManagementHeaders } from './Base.store';
import { IAPIVMSComparison } from '../Modules/Shared/Interfaces';
import { apiUrls } from './API.url';
import { PassengerLogisticsModel } from '../Modules/Shared/Models/PassengerLogisticsModel.model';
import { AirsideLogisticsModel, SettingBaseModel } from '../Modules/Shared';

export class AirsideLogisticsStore {
  @observable public mapValuechanged: boolean = false;
  @observable public towBarFieldRequired: boolean = false;
  @observable public isRampsideShuttleAvailableData: number = 2;
  @observable public isAdditionalFeeRampsideData: number = 4;
  @observable public additionalFee: string = '';
  @observable public walkingDistance: string = '';
  
  
  @observable public disableSaveButton: boolean = true;
  @observable public logisticsData: SettingBaseModel[] = [];

  @observable public operationalInsightsData: AirsideLogisticsModel = new AirsideLogisticsModel();
  @observable public otherNameField: string = '';
  @observable public additionalInfo: string = '';

  @observable public isValueChanged: boolean = false;
  
  @action
  public getLocationOperationalInsightsById(id?: number): Observable<IAPIVMSComparison> {
    const http: HttpClient = new HttpClient({
      baseURL: baseApiPath.vendorManagementNoSqlUrl,
      headers: vendorManagementHeaders,
    });
    return http.get<IAPIPageResponse<IAPIVMSComparison>>(`/${apiUrls.operationalInsight}/${id}`).pipe(
      Logger.observableCatchError,
      map(response => PassengerLogisticsModel.deserialize(response))
    );
  }

  @action
  public upsertVendorLocationAirsideLogistics(
    payload: AirsideLogisticsModel,
  ): Observable<AirsideLogisticsModel> {
    const http = new HttpClient({ headers: vendorManagementHeaders });
    payload.id = payload.id === null ? 0 : payload.id;
    const isNewRequest: boolean = !(payload.id != null && payload.id !== 0);
    const upsertRequest: Observable<AirsideLogisticsModel> = isNewRequest
      ? http.post<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.airsideLogisticHuman}`, payload)
      : http.put<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.airsideLogisticHuman}/${payload.id}`, payload);
    return upsertRequest.pipe(
      Logger.observableCatchError,
      tap(() =>
        AlertStore.info('Airside Logistics data saved successfully!')
      ),
      map(response => AirsideLogisticsModel.deserialize(response))
    );
  }
}
