import { action, observable } from 'mobx';
import { apiUrls } from '../API.url';
import { map, tap } from 'rxjs/operators';
import { Logger } from '@uplink-shared/security';
import { baseApiPath, HttpClient } from '@uplink/shared';
import { Observable } from 'rxjs';
import { vendorManagementHeaders } from '../Base.store';
import { IAPIGridRequest, IAPIPageResponse, Utilities } from '@wings-shared/core';
import {
  IAPIVMSVendorComparison,
  LocationHoursModel,
  VendorLocationModel,
  VendorOnBoardSlideEightModel,
  VendorOnBoardSlideSevenModel,
} from '../../Modules/Shared';
import { AlertStore } from '@uvgo-shared/alert';

export class SlideSevenStore {
  @observable public onboardingHoursList: LocationHoursModel[] = [];

  @action
  public getByVendorId(vendorId?: number): Observable<IAPIPageResponse<VendorOnBoardSlideSevenModel>> {
    const http: HttpClient = new HttpClient({ headers: vendorManagementHeaders });
    return http
      .get<any>(
        `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorOnBoardSlideSeven}/GetByVendorId?vendorId=${vendorId}`
      )
      .pipe(
        Logger.observableCatchError,
        map(response => {
          return { ...response };
        })
      );
  }

  @action
  public upsertSlideSeven(payload: VendorOnBoardSlideSevenModel): Observable<VendorOnBoardSlideSevenModel> {
    const http = new HttpClient({ headers: vendorManagementHeaders });
    payload.id = payload.id === null ? 0 : payload.id;
    const isNewRequest: boolean = !(payload.id != null && payload.id !== 0);
    const upsertRequest: Observable<VendorOnBoardSlideSevenModel> = isNewRequest
      ? http.post<any>(`${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorOnBoardSlideSeven}`, payload)
      : http.put<any>(
        `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorOnBoardSlideSeven}/${payload.id}`,
        payload
      );
    return upsertRequest.pipe(
      Logger.observableCatchError,
      tap(() => AlertStore.info('Slide seven data saved successfully!')),
      map(response => response)
    );
  }

  @action
  public getOnBoardingHours(vendorId: number, tempLocationId: string): Observable<LocationHoursModel[]> {
    const http: HttpClient = new HttpClient({
      headers: vendorManagementHeaders,
    });
    return http
      .get<IAPIPageResponse<IAPIVMSVendorComparison>>(
        `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorOnboardHours}/${vendorId},${tempLocationId}`
      )
      .pipe(
        Logger.observableCatchError,
        map(response => {
          this.onboardingHoursList = LocationHoursModel.deserializeList(response);
          return { ...response, results: this.onboardingHoursList };
        })
      );
  }

  @action
  public deleteOnBoarding(vendorOnboardHoursIds: number[]): Observable<LocationHoursModel[]> {
    const payload = {
      userId: 'string',
      vendorOnboardHoursIds,
    };
    const http = new HttpClient({ headers: vendorManagementHeaders });
    const upsertRequest: Observable<LocationHoursModel[]> = http.delete<any>(
      `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorOnboardHours}/DeleteList`,
      payload
    );
    return upsertRequest.pipe(
      Logger.observableCatchError,
      map(response => LocationHoursModel.deserializeList(response))
    );
  }

  @action
  public updateVendorOnBoardHours(payload: LocationHoursModel[]): Observable<LocationHoursModel[]> {
    const http = new HttpClient({ headers: vendorManagementHeaders });
    const upsertRequest: Observable<LocationHoursModel[]> = http.put<any>(
      `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorOnboardHours}/Update`,
      payload
    );
    return upsertRequest.pipe(
      Logger.observableCatchError,
      map(response => LocationHoursModel.deserializeList(response))
    );
  }

  @action
  public addVendorOnBoardHours(payload: LocationHoursModel[]): Observable<LocationHoursModel[]> {
    const http = new HttpClient({ headers: vendorManagementHeaders });
    const upsertRequest: Observable<LocationHoursModel[]> = http.post<any>(
      `${baseApiPath.vendorManagementCoreUrl}/${apiUrls.vendorOnboardHours}`,
      payload
    );
    return upsertRequest.pipe(
      Logger.observableCatchError,
      map(response => LocationHoursModel.deserializeList(response))
    );
  }

}
