import { createStyles, Theme } from '@material-ui/core';
export const styles = (theme: Theme) =>
  createStyles({
    customHeight: {
      height: 'calc(100vh - 250px)',
    },
    buttonContainer:{
      '& button:first-child':{
        marginRight: theme.spacing(2),
        minWidth: '140px !important',
        boxShadow:'none'
      },
      '& .MuiSvgIcon-root':{
        width: '16px !important',
        height: '16px !important',
      },
      '& button:first-child:hover':{
        backgroundColor: '#63A4FF !important',
        border: 'none',
        color: '#FFFFFF'
      },
    },
    root:{
      color: '#1976D2'
    }
  });